var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selection-modal edit-event-modal" }, [
    _c(
      "div",
      { staticClass: "selection-modal-content" },
      [
        _c(
          "button",
          {
            staticClass: "close-button",
            attrs: { title: "Fermer" },
            on: {
              click: function ($event) {
                return _vm.closeButtonClickHandler()
              },
            },
          },
          [_c("icon", { attrs: { icon: "panier_picto_suppr" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "transition",
          {
            attrs: { name: _vm.transition, mode: "out-in" },
            on: { "before-enter": _vm.beforeEnter },
          },
          [
            _vm.step === 1
              ? _c("event-brief-step1-form", {
                  attrs: { "selection-manager": _vm.selectionManager },
                  on: {
                    submitted: _vm.onStep1FormSubmitted,
                    cancelled: _vm.onStep1FormCancelled,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.step === 2
              ? _c("event-brief-step2-form", {
                  attrs: { "selection-manager": _vm.selectionManager },
                  on: {
                    submitted: _vm.onStep2FormSubmitted,
                    cancelled: _vm.onStep2FormCancelled,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.step === 3
              ? _c("event-brief-step3-form", {
                  attrs: { "selection-manager": _vm.selectionManager },
                  on: {
                    submitted: _vm.onStep3FormSubmitted,
                    cancelled: _vm.onStep3FormCancelled,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }