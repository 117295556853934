import Component from '../component';

export default class NavBar extends Component {
  constructor(...args) {
    super(...args);
    this._retriveDOM();
  }

  _retriveDOM() {
    this.dom.btnOpenBurger = this.dom.component.querySelectorAll(
      '.js-open-menu',
    );
    this.dom.menuBackground = this.dom.component.querySelector(
      '.js-menu-background',
    );
    this.dom.btnCloseBurger = this.dom.component.querySelector(
      '.js-close-menu',
    );
    this.dom.menu = this.dom.component.querySelector('.js-menu');
    this.dom.nav = this.dom.component.querySelector('.first-row-navbar');
  }

  bind() {
    for (let i = 0; i < this.dom.btnOpenBurger.length; i++) {
      this.dom.btnOpenBurger[i].addEventListener('click', () => {
        this._openMenu();
      });
    }

    if (this.dom.btnCloseBurger) {
      this.dom.btnCloseBurger.addEventListener('click', () => {
        this._closeMenu();
      });
    }
  }

  _openMenu() {
    if (this.dom.menuBackground) this.dom.menuBackground.classList.add('show');
    if (this.dom.menu) this.dom.menu.classList.add('show');
  }

  _closeMenu() {
    if (this.dom.menuBackground) {
      this.dom.menuBackground.classList.remove('show');
    }
    if (this.dom.menu) {
      this.dom.menu.classList.remove('show');
    }
  }
}
