<template>
  <div class="selection-modal edit-event-modal">
    <div class="selection-modal-content">
      <button
        class="close-button"
        title="Fermer"
        @click="closeButtonClickHandler()"
      >
        <icon icon="panier_picto_suppr" />
      </button>
      <transition
        :name="transition"
        mode="out-in"
        @before-enter="beforeEnter"
      >
        <event-brief-step1-form
          v-if="step === 1"
          :selection-manager="selectionManager"
          @submitted="onStep1FormSubmitted"
          @cancelled="onStep1FormCancelled"
        />
        <event-brief-step2-form
          v-if="step === 2"
          :selection-manager="selectionManager"
          @submitted="onStep2FormSubmitted"
          @cancelled="onStep2FormCancelled"
        />
        <event-brief-step3-form
          v-if="step === 3"
          :selection-manager="selectionManager"
          @submitted="onStep3FormSubmitted"
          @cancelled="onStep3FormCancelled"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import Icon from '../../../icon/Icon.vue';
import EventBriefStep1Form from './common/EventBriefStep1Form.vue';
import EventBriefStep2Form from './common/EventBriefStep2Form.vue';
import EventBriefStep3Form from './common/EventBriefStep3Form.vue';
import { pushEventToDataLayer } from '../../../../helpers/data-layer-helper';
import { SELECTION_CLOSE_BRIEF } from '../../../../events/data-layer-events';

export default {
  name: 'EditEventBriefModal',
  components: {
    Icon,
    EventBriefStep1Form,
    EventBriefStep2Form,
    EventBriefStep3Form,
  },
  props: {
    close: {
      type: Function,
      required: true,
    },
    selectionManager: {
      type: Object,
      required: true,
    },
    device: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      transition: 'brief-transition',
    };
  },
  methods: {
    beforeEnter() {
      if (this.device === 'mobile') { // scroll wrapper div to the top to allow correct transition of 100% on y axis
        document.querySelector('.selection-modal-manager').scrollTop = 0;
      }
    },
    onStep1FormCancelled() {
      this.close();
    },
    onStep1FormSubmitted() {
      this.transition = 'brief-transition';
      this.step = 2;
    },
    onStep2FormCancelled() {
      this.transition = 'reverse-brief-transition';
      this.step = 1;
    },
    onStep2FormSubmitted() {
      this.transition = 'brief-transition';
      this.step = 3;
    },
    onStep3FormCancelled() {
      this.transition = 'reverse-brief-transition';
      this.step = 2;
    },
    onStep3FormSubmitted(response) {
      if (response && response.redirect) {
        window.location.href = response.redirect;
      }
      this.close();
    },
    closeButtonClickHandler() {
      pushEventToDataLayer(SELECTION_CLOSE_BRIEF, {
        selection_close_brief_step: this.step,
      });
      this.close();
    },
  },
};
</script>

<style scoped>
/* slide top -> bottom */

.brief-transition-enter-active {
  transition: 1s;
}

.brief-transition-leave-active {
  transition: 0s;
}

.brief-transition-enter {
  transform: translate(0, 100%);
}

.brief-transition-leave-to {
  transform: translate(0, -100%);
}

/* reverse brief-transition bottom -> top */

.reverse-brief-transition-enter-active {
  transition: 1s;
}

.reverse-brief-transition-leave-active {
  transition: 0s;
}

.reverse-brief-transition-enter {
  transform: translate(0, -100%);
}

.reverse-brief-transition-leave-to {
  transform: translate(0, 100%);
}
</style>
