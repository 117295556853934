var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selection
    ? _c(
        "div",
        {
          class: { "is-empty": _vm.selection.items.length === 0 },
          staticStyle: { height: "100%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "empty-container",
              class: { hidden: _vm.selection.items.length !== 0 },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("p", { staticClass: "empty-description" }, [
                _vm._v(
                  "\n      Parcourez nos offres pour ajouter un lieu et des services à votre événement.\n    "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "basket-container",
              class: { hidden: _vm.selection.items.length === 0 },
            },
            [
              _c("div", { staticClass: "basket-result" }, [
                _c(
                  "div",
                  { ref: "wrapper", staticClass: "basket-items" },
                  [
                    _vm._l(_vm.sections, function (section) {
                      return _c("div", { key: section.type }, [
                        _c(
                          "div",
                          {
                            ref: "type-" + section.type,
                            refInFor: true,
                            staticClass: "basket-item",
                            class: {
                              hidden:
                                _vm.selection.items.filter(function (item) {
                                  return item.item_sub_type === section.type
                                }).length === 0,
                            },
                          },
                          [
                            _c("div", { staticClass: "headline" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "span",
                                  { staticClass: "type basket-1" },
                                  [
                                    _c("icon", {
                                      attrs: { icon: section.icon },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(section.label) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "details" }, [
                              _c(
                                "ul",
                                [
                                  _c(
                                    "transition-group",
                                    {
                                      attrs: { name: "list" },
                                      on: { "before-enter": _vm.beforeEnter },
                                    },
                                    _vm._l(
                                      _vm.selection.items.filter(function (
                                        item
                                      ) {
                                        return (
                                          item.item_sub_type === section.type
                                        )
                                      }),
                                      function (item) {
                                        return _c(
                                          "li",
                                          {
                                            key: item.id,
                                            staticClass: "filter-2",
                                            attrs: {
                                              "data-type":
                                                "type-" + section.type,
                                            },
                                          },
                                          [
                                            _c("div", { staticClass: "left" }, [
                                              _c(
                                                "div",
                                                { staticClass: "item-name" },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(item.item_title) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              item.item_options.quantity ||
                                              item.item_options.duration ||
                                              item.item_options.pack ||
                                              item.item_options.period
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-options",
                                                      staticStyle: {
                                                        margin: "3px 0",
                                                      },
                                                    },
                                                    [
                                                      item.item_options.quantity
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item-quantity",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          x" +
                                                                  _vm._s(
                                                                    item
                                                                      .item_options
                                                                      .quantity
                                                                      .label
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.item_options.duration
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item-duration",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    item
                                                                      .item_options
                                                                      .duration
                                                                      .label
                                                                  ) +
                                                                  "h\n                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.item_options.pack
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item-pack",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "truncate"
                                                                    )(
                                                                      item
                                                                        .item_options
                                                                        .pack
                                                                        .label,
                                                                      28
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      item.item_options.period
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item-period",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    item
                                                                      .item_options
                                                                      .period
                                                                      .label
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "item-price",
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                    "margin-bottom": "3px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(item.item_price) +
                                                      " "
                                                  ),
                                                  section.type === "place"
                                                    ? _c("span", [_vm._v("*")])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "right" },
                                              [
                                                !_vm.readOnly
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "remove-item",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeItem(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("icon", {
                                                          attrs: {
                                                            icon:
                                                              "panier_picto_suppr",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            section.type === "place"
                              ? _c("div", { staticClass: "additional-infos" }, [
                                  _vm._v(
                                    "\n              * les prix seront validés suite à la demande de disponibilités et sont ici estimatifs.\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    !_vm.readOnly
                      ? _c("div", { staticClass: "clear-selection" }, [
                          _c(
                            "button",
                            {
                              staticClass: "clear-selection-button",
                              attrs: {
                                title: "Vider les éléments de ma sélection",
                              },
                              on: { click: _vm.clearSelection },
                            },
                            [
                              _c("icon", {
                                attrs: { icon: "selection-trash" },
                              }),
                              _vm._v(
                                "\n            Vider la sélection\n          "
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "total-basket" }, [
                _c(
                  "button",
                  {
                    staticClass: "add-to-selection btn-submit-basket-new",
                    on: { click: _vm.checkSelection },
                  },
                  [
                    _vm._v("\n        DEMANDER LA DISPONIBILITÉ "),
                    _c("br"),
                    _vm._v("ET LE PRIX\n      "),
                  ]
                ),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "empty-title" }, [
      _vm._v("Vous n'avez encore "),
      _c("br"),
      _vm._v("rien selectionné"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "basket-item" }, [
      _c("div", { staticClass: "headline" }, [
        _c("div", { staticClass: "col" }, [
          _c("span", { staticClass: "type basket-1" }, [
            _vm._v("\n                Services Annexes\n              "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "details" }, [
        _c("ul", [
          _c("li", [
            _c("p", { staticClass: "additional-infos" }, [
              _vm._v(
                "\n                  Des coûts supplémentaires à la location s’ajouteront au prix estimatif : "
              ),
              _c("br"),
              _vm._v("\n                  - Frais de gestion"),
              _c("br"),
              _vm._v("\n                  - Forfait ménage"),
              _c("br"),
              _vm._v(
                "\n                  - Snapper (coordinateur d’événement)\n                "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }