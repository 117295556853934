<template>
  <form
    class="generic-form"
    @submit.prevent="submit"
  >
    <div
      v-if="loaded === false"
      class="form-body-placeholder"
    />
    <div
      v-if="loaded === true"
      class="form-body"
    >
      <h3 class="title-4">
        Besoins optionnels
      </h3>
      <event-brief-step-form-errors
        v-if="errors"
        :dismiss="dismissErrors"
        :errors="errors"
      />
      <!-- AGENCEMENT -->
      <div
        v-if="fields.venueSetup === true"
        class="form-row"
      >
        <div class="form-group form-layout">
          <label class="checkbox checkbox-custom">
            <input
              v-model="payload.withVenueSetup"
              type="checkbox"
            >
            <span>J'ai besoin de plusieurs salles</span>
          </label>
          <textarea
            id="edit-event-layout-desc"
            v-model="payload.venueSetup"
            class="form-control"
            placeholder="Préciser le nombre de salles, leur type et leur capacité"
            :disabled="!payload.withVenueSetup"
          />
        </div>
      </div>
      <!-- HERBERGEMENT -->
      <div
        v-if="false"
        class="form-row form-row-bedding"
      >
        <div class="form-group form-bedding">
          <label class="checkbox checkbox-custom">
            <input
              v-model="payload.withBedding"
              type="checkbox"
            >
            <span>J'ai besoin d'un hébergement</span>
          </label>
          <div>
            <input
              id="edit-event-accommodation"
              v-model="payload.beddingNumber"
              type="number"
              min="1"
              class="form-control form-control-inline"
              :disabled="!payload.withBedding"
            >
            couchages
          </div>
        </div>
      </div>
      <!-- EQUIPEMENT -->
      <div
        v-if="fields.equipments === true"
        class="form-row"
      >
        <div class="form-group form-equipments">
          <label class="checkbox checkbox-custom">
            <input
              v-model="payload.withEquipment"
              type="checkbox"
            >
            <span>J'ai besoin d'un équipement particulier</span>
          </label>
          <event-brief-multi-select
            id="edit-event-category"
            v-model="payload.equipments"
            :options="values.equipments"
            :close-on-select="false"
            :searchable="false"
            :hide-selected="false"
            :multiple="true"
            label="label"
            track-by="value"
            placeholder=""
            selected-label="✓"
            select-label=""
            deselect-label=""
            class="no-placeholder"
            :disabled="!payload.withEquipment"
          >
            <template
              slot="selection"
              slot-scope="props"
            >
              <span class="multiselect__resume">{{ joinValues(props.values) }}</span>
            </template>
          </event-brief-multi-select>
        </div>
      </div>
      <!-- REMARQUE -->
      <div
        v-if="fields.remarks === true"
        class="form-row"
      >
        <div class="form-group form-remarks">
          <label class="checkbox checkbox-custom">
            <input
              v-model="payload.withRemarks"
              type="checkbox"
            >
            <span>J'ai des remarques particulières</span>
          </label>
          <textarea
            id="edit-event-remarks"
            v-model="payload.remarks"
            class="form-control"
            placeholder="Accès à la lumière du jour, allergies particulières, contraintes spécifique, pas d'animaux, besoin d'un espace fumeur obligatoirement"
            :disabled="!payload.withRemarks"
          />
        </div>
      </div>
    </div>
    <div class="form-actions">
      <button
        type="button"
        class="ct ct--button cta cta-clear"
        @click="cancel()"
      >
        <icon icon="arrow-left-solid" />
        Prestation (2/3)
      </button>
      <button
        type="submit"
        class="ct ct--button cta"
      >
        C'est parti !
      </button>
    </div>
  </form>
</template>

<script>
import api from '../../../../../helpers/selection-api';
import Icon from '../../../../icon/Icon.vue';
import EventBriefStepFormErrors from './EventBriefFormErrors.vue';
import EventBriefMultiSelect from './EventBriefMultiSelect.vue';

export default {
  name: 'EventBriefStep1Form',
  components: {
    EventBriefMultiSelect,
    EventBriefStepFormErrors,
    Icon,
  },
  props: {
    selectionManager: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      errors: null,
      values: {
        equipments: [],
      },
      fields: {
        withVenueSetup: false,
        venueSetup: false,
        withBedding: false,
        beddingNumber: false,
        withEquipment: false,
        equipments: false,
        withRemarks: false,
        remarks: true,
      },
      payload: {
        withVenueSetup: null,
        venueSetup: null,
        withBedding: null,
        beddingNumber: null,
        withEquipment: null,
        equipments: [],
        withRemarks: null,
        remarks: null,
      },
    };
  },
  mounted: function () {
    Promise
      .all([api.get(), api.getBriefStep3Values()])
      .then(([selection, values]) => {
        const event = selection.data.selection.event;
        // prepare fields choices
        this.values = { ...values.data.data };
        // prepare payload based on current event values
        this.payload = {
          withVenueSetup: event.withVenueSetup,
          venueSetup: event.venueSetup,
          withBedding: event.withBedding,
          beddingNumber: event.beddingNumber,
          withEquipment: event.withEquipment,
          equipments: (event.equipments || []).map((value) => {
            if (this.values.equipments && this.values.equipments.find((cat) => cat.value === value)) {
              return this.values.equipments.find((cat) => cat.value === value);
            }
            return null;
          }).filter((item) => item !== null),
          withRemarks: event.withRemarks,
          remarks: event.remarks,
        };
        // prepare fields to be displayed
        Object.keys(this.fields).forEach((field) => {
          this.fields[field] = typeof values.data.data[field] !== 'undefined';
        });
        this.loaded = true;
      });
  },
  methods: {
    cancel() {
      this.$emit('cancelled');
    },
    submit() {
      const payload = { ...this.payload };
      payload.venueSetup = payload.withVenueSetup ? payload.venueSetup : null;
      payload.equipments = payload.withEquipment ? payload.equipments.map((option) => option.value) : null;
      payload.beddingNumber = payload.withBedding ? payload.beddingNumber : null;
      payload.remarks = payload.withRemarks ? payload.remarks : null;
      this.selectionManager.updateBriefStep3(payload)
        .then((response) => {
          this.$emit('submitted', response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.errors = error.response.data.data;
          }
        });
    },
    dismissErrors() {
      this.errors = null;
    },
    joinValues(values) {
      if (!values) {
        return '';
      }
      return values.map((cat) => cat.label).join(', ');
    },
  },
};
</script>
