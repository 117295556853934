import Vue from 'vue';
import App from './SelectionBullet.vue';

Vue.config.devtools = false;
Vue.config.productionTip = false;

const mount = (selector, dispatcher) => {
  new Vue({ render: (h) => h(App, { props: { dispatcher } }) }).$mount(selector);
};

export default mount;
