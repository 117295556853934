import EventEmitter from 'eventemitter3';

import States from '../helpers/states';
import { findAllDOM, findDOM } from '../lib/dom/findDOM';

export default class Component extends EventEmitter {
  constructor(element, dispatcher) {
    super();

    this.dom = {
      component: element,
    };

    this.dispatcher = dispatcher;
    this.persist = this._getPersitance();
    this.mediaLoaded = false;
  }

  init() {}

  bind() {}

  unbind() {}

  mediaLoadedHandler() {
    this.mediaLoaded = true;
  }

  resize(width = States.width, height = States.height) {}

  _find(selector, container = this.dom.component) {
    return findDOM(selector, container);
  }

  _findAll(selector, container = this.dom.component) {
    return findAllDOM(selector, container);
  }

  _getPersitance() {
    return !(
      States.dom.body.contains(this.dom.component) &&
      States.dom.page.contains(this.dom.component)
    );
  }
}
