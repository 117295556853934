import Component from '../component';
import { scrollToYOffset } from '../../helpers/scroll-to-helper';

export default class Header extends Component {
  constructor(...args) {
    super(...args);
    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.dom.cta_scroll = this.dom.component.querySelector(
      '.icon-container-header',
    );
  }

  bind() {
    // Auto Scroll
    if (this.dom.cta_scroll) {
      this.dom.cta_scroll.addEventListener('click', () => {
        scrollToYOffset(this.dom.component.getBoundingClientRect().height);
      });
    }
  }
}
