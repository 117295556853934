var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.drink
    ? _c("div", { staticClass: "selection-modal add-menu-modal" }, [
        _c("div", { staticClass: "selection-modal-content" }, [
          _c(
            "button",
            {
              staticClass: "close-button",
              attrs: { title: "Fermer" },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_c("icon", { attrs: { icon: "panier_picto_suppr" } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "visual" }, [
            _c("div", { staticClass: "img-wrapper" }, [
              _c("img", {
                attrs: {
                  src: _vm.drink.image.url,
                  alt: "Aperçu de la prestation",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "functional" }, [
            _c("div", { staticClass: "content" }, [
              _c("h2", { staticClass: "title-2" }, [
                _vm._v(
                  '\n          Ajouter "' +
                    _vm._s(_vm.drink.title) +
                    '" à votre sélection ?\n        '
                ),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "menu-infos" }, [
                    _c("div", { staticClass: "quantity-info" }, [
                      _c("span", { staticClass: "title-2" }, [_vm._v("x")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.payload.quantity,
                            expression: "payload.quantity",
                          },
                        ],
                        staticClass: "quantity-input",
                        attrs: {
                          type: "number",
                          min: _vm.drink.add_options.quantity.min,
                          max: _vm.drink.add_options.quantity.max,
                        },
                        domProps: { value: _vm.payload.quantity },
                        on: {
                          change: _vm.inputUpdate,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.payload,
                              "quantity",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "price-info" }, [
                      _c("span", { staticClass: "title-1" }, [
                        _vm._v(
                          _vm._s(_vm._f("format_price")(_vm.price)) + " € HT"
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.drink.add_options.quantity
                    ? _c(
                        "div",
                        { staticClass: "slider-wrapper" },
                        [
                          _c("p", { staticClass: "text-1" }, [
                            _vm._v(
                              "\n              Sélectionnez la quantité désirée :\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("slider", {
                            ref: "quantitySlider",
                            attrs: {
                              config: _vm.slider,
                              values: [_vm.payload.quantity],
                            },
                            on: { change: _vm.sliderUpdate },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "ct ct--button cta cta-clear",
                        on: {
                          click: function ($event) {
                            return _vm.close()
                          },
                        },
                      },
                      [_vm._v("\n              Annuler\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "ct ct--button cta",
                        attrs: { type: "submit" },
                      },
                      [_vm._v("\n              Ajouter\n            ")]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }