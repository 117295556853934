import { buildItemAddedToSelectionNofication } from './notification-factory';
import { SELECTION_ITEM_ADDED } from '../events/selection-events';

export default class ServerEventsManager {
  constructor(dispatcher, notifier) {
    this.dispatcher = dispatcher;
    this.notifier = notifier;
  }

  init() { // handle events found in the DOM
    if (typeof APP_EVENTS === 'undefined') {
      return;
    }
    // handle item_added_to_selection events
    APP_EVENTS.filter((event) => event.event === 'item_added_to_selection').forEach((event) => {
      const item = event.payload.item;
      const isFirst = item.item_type === 'place' && item.is_first === true;
      if (isFirst === false) {
        this.notifier.notify(buildItemAddedToSelectionNofication(item));
      }
      this.dispatcher.emit(SELECTION_ITEM_ADDED, item);
    });
  }
}
