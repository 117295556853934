import Component from '../component';

export default class Basket extends Component {
  hide() {
    this.dom.component.style.display = 'none';
  }

  show() {
    this.dom.component.style.display = 'block';
  }
}
