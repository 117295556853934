import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: window.SnApp.SENTRY_BROWSER_DSN || '',
  allowUrls: window.SnApp.SENTRY_ALLOWED_URLS || [],
});

if (window.SnApp.SENTRY_USER_USERNAME) {
  Sentry.setUser({
    username: window.SnApp.SENTRY_USER_USERNAME,
  });
}
