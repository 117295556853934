export default class Swipe {
  constructor(wrapper, callback, sensibility = 30) {
    this.dom = {
      wrapper,
    };
    this.callback = callback;
    this.sensibility = sensibility;

    this.swipe = {};

    this._touchstartSwipe = this._touchstartSwipe.bind(this);
    this._touchendSwipe = this._touchendSwipe.bind(this);

    this._bind();
  }

  _bind() {
    // https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection - start
    let supportsPassive = false;
    try {
      const opts = Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassive = true;
        },
      });
      window.addEventListener('testPassive', null, opts);
      window.removeEventListener('testPassive', null, opts);
    } catch (e) {
      // continue regardless of error
    }
    // https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection - end
    this.dom.wrapper.addEventListener('touchstart', this._touchstartSwipe, supportsPassive ? { passive: true } : false);
    this.dom.wrapper.addEventListener('touchend', this._touchendSwipe, supportsPassive ? { passive: true } : false);
  }

  unbind() {
    this.dom.wrapper.removeEventListener('touchstart', this._touchstartSwipe);
    this.dom.wrapper.removeEventListener('touchend', this._touchendSwipe);
  }

  _touchstartSwipe(event) {
    this.swipe.startX = event.changedTouches[0].pageX;
  }

  _touchendSwipe(event) {
    const x = event.changedTouches[0].pageX;
    if (x > this.swipe.startX && x - this.swipe.startX > this.sensibility) {
      this.callback('left');
    } else if (
      x < this.swipe.startX &&
      x - this.swipe.startX < -this.sensibility
    ) {
      this.callback('right');
    }
  }
}
