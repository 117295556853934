import delegate from '../../lib/dom/delegate';
import Component from '../component';

export default class BasketHeadline extends Component {
  constructor(...args) {
    super(...args);
    this._getDom();
    this.is_open = false;
  }

  _getDom() {
    this.dom.sidebar = document.body.querySelector('.search-sidebar');
    this.dom.items = this.dom.component.querySelectorAll('.js-item');
    this.dom.basket_submenu_holder = this.dom.component.querySelector('.js-submenu-holder');
  }

  getLength() {
    return this.dom.items.length;
  }

  init() {
    super.init();
    this._bind();
  }

  _bind() {
    delegate(this.dom.component, '.js-item', 'click', (e) => {
      const item = e.delegateTarget;
      this._clearActive();
      item.classList.add('active');
      this.emit(BASKET_HEADLINE_EVENT.CLICKED, {
        name: item.getAttribute('data-name'),
      });
    });

    // Bind opener (Mobile)
    delegate(document.body, '.js-open-basket', 'click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      if (this.is_open == false) this.showSidebar();
      else this.hideSidebar();
    });

    // Bind Closer (Mobile)
    delegate(document.body, '.js-close-basket', 'click', (e) => {
      this.hideSidebar();
    });

    // Bind show on place search (desktop)
    delegate(document.body, '.js-show-basket', 'click', (e) => {
      const item = document.body.querySelector('.your-demand.js-item');
      if (item) {
        e.preventDefault();
        this._clearActive();
        item.classList.add('active');
        this.emit(BASKET_HEADLINE_EVENT.CLICKED, {
          name: item.getAttribute('data-name'),
        });
      }
    });
  }

  _clearActive() {
    for (let i = 0, j = this.dom.items.length; i < j; i++) {
      this.dom.items[i].classList.remove('active');
    }
  }

  hide() {
    this.dom.component.style.display = 'none';
  }

  show() {
    this.dom.component.style.display = 'block';
  }

  hideSidebar() {
    if (this.is_open === true) {
      this.dom.sidebar.style.display = 'none';
      this.is_open = false;
    }
  }

  showSidebar() {
    if (this.is_open === false) {
      this.dom.sidebar.style.display = 'block';
      this.is_open = true;
    }
  }
}

export const BASKET_HEADLINE_EVENT = {
  CLICKED: '__bhe_clicked',
  INIT: '__bhe_init',
  TRASH_BASKET: '__bhe_trash_basket',
};
