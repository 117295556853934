<template>
  <div class="selection-modal authentication-required-modal">
    <div class="selection-modal-content">
      <button
        class="close-button"
        title="Fermer"
        @click="close()"
      >
        <icon icon="panier_picto_suppr" />
      </button>
      <div class="full">
        <div class="content">
          <h2 class="title-2">
            Authentifiez-vous
          </h2>
          <p class="text-1">
            Pour continuer vous devez vous authentifier. La création d’un compte ne prend que quelques
            secondes.<br>
            Si vous avez déjà un compte, connectez-vous.
          </p>
          <a
            :href="loginUrl"
            class="ct ct--button cta cta-clear"
          >
            Connexion
          </a>
          <a
            :href="registerUrl"
            class="ct ct--button cta"
          >
            Inscription
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FOSRouting from '../../../../modules/routing';
import Icon from '../../../icon/Icon.vue';

export default {
  name: 'AuthenticationRequiredModal',
  components: {
    Icon,
  },
  props: {
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loginUrl: FOSRouting.generate('app_login'),
      registerUrl: FOSRouting.generate('app_register'),
    };
  },
};
</script>
