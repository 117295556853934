export const buildItemRemovedFromSelectionNofication = (selectionItem) => {
  const itemType = selectionItem.item_sub_type;
  const itemTitle = selectionItem.item_title;
  switch (itemType) {
    case 'place':
      return {
        title: 'Ce lieu a été supprimé de votre sélection',
        content: `"${itemTitle}" a bien été supprimé de votre sélection.`,
        icon: 'selection-trashed',
      };
    case 'menu':
      return {
        title: 'Cette prestation traiteur a été supprimée de votre sélection',
        content: `La prestation traiteur "${itemTitle}" a été supprimée à votre sélection.`,
        icon: 'selection-trashed',
      };
    case 'animation':
      return {
        title: 'Cette animation a été supprimée de votre sélection',
        content: `L’animation "${itemTitle}" a été supprimée à votre sélection. Vous pouvez ajouter d’autres éléments à votre sélection avant de valider la disponibilité et le prix.`,
        icon: 'selection-trashed',
      };
    default:
      return {
        title: 'Cette prestation a été supprimée de votre sélection',
        content: `La prestation "${itemTitle}" a été supprimée à votre sélection.`,
        icon: 'selection-trashed',
      };
  }
};

export const buildItemAddedToSelectionNofication = (selectionItem, isMobile) => {
  const actions = [];
  if (isMobile) {
    actions.push({
      label: 'Voir ma sélection',
      class: 'cta-infos selection-toggle-button',
    });
  }

  const itemType = selectionItem.item_sub_type;
  const itemTitle = selectionItem.item_title;
  switch (itemType) {
    case 'place':
      return {
        title: 'Ce lieu a été ajouté à votre sélection',
        content: `"${itemTitle}" a bien été ajouté à votre sélection. Vous pouvez ajouter d’autres lieux à votre sélection avant de valider la disponibilité et le prix.`,
        actions: actions,
        duration: 5000,
      };
    case 'menu':
      return {
        title: 'Ce traiteur a été ajouté à votre sélection',
        content: `La prestation traiteur "${itemTitle}" a bien été ajoutée à votre sélection. Vous pouvez ajouter d’autres éléments à votre sélection avant de valider la disponibilité et le prix.`,
        actions: actions,
        duration: 5000,
      };
    case 'animation':
      return {
        title: 'Cette animation a été ajoutée à votre sélection',
        content: `L’animation "${itemTitle}" a bien été ajoutée à votre sélection. Vous pouvez ajouter d’autres éléments à votre sélection avant de valider la disponibilité et le prix.`,
        actions: actions,
        duration: 5000,
      };
    default:
      return {
        title: 'Cette prestation a été ajoutée à votre sélection',
        content: `La prestation "${itemTitle}" a bien été ajoutée à votre sélection. Vous pouvez ajouter d’autres éléments à votre sélection avant de valider la disponibilité et le prix.`,
        actions: actions,
        duration: 5000,
      };
  }
};
