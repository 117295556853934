<template>
  <div class="selection-modal merge-selections-modal">
    <div class="selection-modal-content">
      <button
        class="close-button"
        title="Fermer"
        @click="close()"
      >
        <icon icon="panier_picto_suppr" />
      </button>
      <div class="full">
        <div class="content">
          <h2 class="title-2">
            Vous avez déjà une demande en cours !
          </h2>
          <ul class="text-1">
            <li>Date : le {{ selection.date }}</li>
            <li>Type : {{ selection.type }}</li>
            <li>Invités : {{ selection.guests }}</li>
          </ul>
          <p class="text-1">
            <strong>S'agit-il du même événement ?</strong>
          </p>
          <button
            class="ct ct--button cta cta-clear"
            @click="brief()"
          >
            <icon icon="times-solid" />
            Non, c'est une nouvelle demande
          </button>
          <button
            class="ct ct--button cta"
            @click="merge()"
          >
            <icon icon="check-solid" />
            Oui, c'est le même événement
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../../../icon/Icon.vue';

export default {
  name: 'MergeSelectionsModal',
  components: {
    Icon,
  },
  props: {
    close: {
      type: Function,
      required: true,
    },
    selection: {
      type: Object,
      default: function () {
        return {
          date: null,
          guests: null,
          type: null,
        };
      },
    },
    selectionManager: {
      type: Object,
      required: true,
    },
  },
  methods: {
    merge: function () {
      this.selectionManager.mergeSelections().then((redirectUrl) => {
        window.location.href = redirectUrl;
      });
    },
    brief: function () {
      this.selectionManager.requestQuotation();
      this.close();
    },
  },
};
</script>
