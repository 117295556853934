import debounce from 'just-debounce-it';
import { EventEmitter } from 'eventemitter3';

// setup sentry js as soon as possible
import '../helpers/sentry';

import '../lib/lib';
import setUpDataLayerInterceptors from '../helpers/axios-interceptors';
import setupDataLayerEventTracking from '../helpers/data-layer-event-tracking';
import handleHubspotForms from '../helpers/hubspot-form';
import bindAll from '../lib/common/bind-all';
import isArray from '../lib/common/is-array';
import capitalize from '../lib/string/capitalize';
import States from '../helpers/states';

export default class Main {
  constructor(map, device) {
    this._device = device;
    this._map = map;

    this.Components = {};
    this.Page = null;

    this._isFirstInit = true;

    bindAll(this, [
      '_resize',
      '_pageLoadedHandler',
    ]);

    this._retrieveDom();
    this._bind();

    this.dispatcher = new EventEmitter();
    this._initContext();
  }

  /**
   * Retrieve DOM Elements
   */
  _retrieveDom() {
    this._dom = {
      body: document.body,
      main: document.querySelector('body main'),
      page: document.querySelector('body main .page'),
    };

    States.dom = this._dom;
  }

  /**
   * Bind events
   */
  _bind() {
    window.addEventListener('resize', debounce(this._resize, 200));
  }

  /**
   * Initialise the current context
   */
  _initContext() {
    setUpDataLayerInterceptors();
    setupDataLayerEventTracking();
    handleHubspotForms();
    this._retrieveDomProperties();
    this._initComponents();
    this._initPage();
    this._pageLoadedHandler();
    this._resize();
  }

  /**
   * Retrieve page properties: entity & page name
   */
  _retrieveDomProperties() {
    this._properties = {
      entity: this._dom.page.getAttribute('data-entity').toLowerCase(),
      page: this._dom.page.getAttribute('data-page').toLowerCase(),
    };
  }

  /**
   * Detect available components in Page and instanciate them
   */
  _initComponents() {
    const container = this._isFirstInit ? this._dom.body : this._dom.page;
    const components = container.querySelectorAll('[data-component]');
    for (let i = 0, j = components.length; i < j; i++) {
      const component = components[i];
      const name = component.getAttribute('data-component');
      if (this._map.components.hasOwnProperty(name)) {
        if (this.Components.hasOwnProperty(capitalize(name))) {
          if (isArray(this.Components[capitalize(name)])) {
            this.Components[capitalize(name)].push(
              new this._map.components[name](component, this.dispatcher),
            );
          } else {
            this.Components[capitalize(name)] = [
              this.Components[capitalize(name)],
              new this._map.components[name](component, this.dispatcher),
            ];
          }
        } else {
          this.Components[capitalize(name)] = new this._map.components[
            name
          ](component, this.dispatcher);
        }
      } else {
        throw new Error(
          `Main${capitalize(this._device)} :: you are calling non-existing '${name}' component in your map file. Please check your map file.`,
        );
      }
    }

    States.Components = this.Components;
  }

  /**
   * Detect current page, find class and instanciate it
   */
  _initPage() {
    if (this._map.pages.hasOwnProperty(this._properties.entity)) {
      if (
        this._map.pages[this._properties.entity].hasOwnProperty(
          this._properties.page,
        )
      ) {
        this.Page = new this._map.pages[this._properties.entity][
          this._properties.page
        ](this._dom.page, this.Components, this._device, this.dispatcher);
      } else {
        throw new Error(
          `Main${capitalize(this._device)} :: you are calling non-existing '${
            this._properties.page
          }' page for entity '${
            this._properties.page
          }' in your map file. Please check your map file.`,
        );
      }
    } else {
      throw new Error(
        `Main${capitalize(this._device)} :: you are calling non-existing '${
          this._properties.entity
        }' page entity in your map file. Please check your map file.`,
      );
    }

    States.Page = this.Page;
  }

  /**
   * Triggered when the window is resize
   */
  _resize() {
    const width = window.innerWidth || document.documentElement.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight;

    this._browseComponents('resize', [width, height]);

    if (this.Page) this.Page.resize(width, height);

    States.width = width;
    States.height = height;
  }

  /**
   * Triggered when the page is loaded
   */
  _pageLoadedHandler() {
    this._browseComponents('mediaLoadedHandler');
    this._browseComponents('init');
    this._browseComponents('bind');

    this.Page.init();
    this.Page.bind();
  }

  _browseComponents(methodName, argsArray = [], callback = null) {
    for (const key in this.Components) {
      if (this.Components.hasOwnProperty(key)) {
        // Browse all components
        let component = null;
        if (isArray(this.Components[key])) {
          for (let i = 0, j = this.Components[key].length; i < j; i++) {
            const componentsType = this.Components[key];
            component = componentsType[i];
            if (component.persist === false || ((methodName !== 'init' || this._isFirstInit === true) && methodName !== 'unbind')) {
              component[methodName].apply(component, argsArray);
            }
          }
        } else {
          component = this.Components[key];
          if (component.persist === false || ((methodName !== 'init' || this._isFirstInit === true) && methodName !== 'unbind')) {
            component[methodName].apply(component, argsArray);
          }
        }

        // Callback to call
        if (typeof callback === 'function') {
          callback.call(callback, component, key);
        }
      }
    }

    if (methodName === 'init') this._isFirstInit = false;
  }
}
