var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.place
    ? _c("div", { staticClass: "selection-modal add-place-modal" }, [
        _c("div", { staticClass: "selection-modal-content" }, [
          _c(
            "button",
            {
              staticClass: "close-button",
              attrs: { title: "Fermer" },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_c("icon", { attrs: { icon: "panier_picto_suppr" } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "visual" }, [
            _c("div", { staticClass: "img-wrapper" }, [
              _c("img", {
                attrs: { src: _vm.place.image.url, alt: "Aperçu du lieu" },
              }),
            ]),
            _vm._v(" "),
            _vm.item.context === "list"
              ? _c(
                  "a",
                  {
                    staticClass: "ct ct--button cta cta-infos",
                    attrs: { href: _vm.place.uri, title: "Voir les détails" },
                  },
                  [_vm._v("\n        Voir les détails\n      ")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "functional" }, [
            _c("div", { staticClass: "content" }, [
              _c("h2", { staticClass: "title-2" }, [
                _vm._v(
                  '\n          Ajouter "' +
                    _vm._s(_vm.place.title) +
                    '" à votre sélection ?\n        '
                ),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "place-infos" }, [
                    _c("div", { staticClass: "radio-wrapper" }, [
                      _c("p", { staticClass: "text-1" }, [
                        _vm._v(
                          "\n                Veuillez choisir le créneau horaire désiré :\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        _vm._l(
                          _vm.place.add_options.period.choices,
                          function (choice) {
                            return _c("li", { key: choice.value }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.payload.period,
                                      expression: "payload.period",
                                    },
                                  ],
                                  attrs: { type: "radio" },
                                  domProps: {
                                    value: choice.value,
                                    checked: _vm._q(
                                      _vm.payload.period,
                                      choice.value
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.payload,
                                        "period",
                                        choice.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("strong", [_vm._v(_vm._s(choice.label))]),
                                _vm._v(
                                  "\n                    (à partir de " +
                                    _vm._s(
                                      _vm._f("format_price")(choice.price)
                                    ) +
                                    " € HT)\n                  "
                                ),
                              ]),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "ct ct--button cta cta-clear",
                        on: {
                          click: function ($event) {
                            return _vm.close()
                          },
                        },
                      },
                      [_vm._v("\n              Annuler\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "ct ct--button cta",
                        attrs: { type: "submit" },
                      },
                      [_vm._v("\n              Ajouter\n            ")]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }