var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selection-modal only-places-selected-modal" },
    [
      _c("div", { staticClass: "selection-modal-content" }, [
        _c(
          "button",
          {
            staticClass: "close-button",
            attrs: { title: "Fermer" },
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_c("icon", { attrs: { icon: "panier_picto_suppr" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "full" }, [
          _c("div", { staticClass: "content" }, [
            _c("h2", { staticClass: "title-2" }, [
              _vm._v(
                "\n          Ajoutez de l'émotion à votre événement\n        "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-1" }, [
              _vm._v(
                "\n          Vous pouvez consulter notre catalogue de traiteurs et d'animations sélectionnés par nos soins et\n          les ajouter facilement à votre sélection.\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "ct ct--button cta cta-clear",
                attrs: { href: _vm.featuredMenusUrl },
              },
              [_vm._v("\n          Consulter les traiteurs\n        ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "ct ct--button cta",
                attrs: { href: _vm.requestQuotationUrl },
              },
              [
                _vm._v(
                  "\n          Valider la disponibilité et le prix\n        "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }