// selection
export const SELECTION_OPEN_BRIEF = 'selection_open_brief';
export const SELECTION_CLOSE_BRIEF = 'selection_close_brief';
// selection tutorial
export const SELECTION_TUTO_DECLINED = 'selection_tuto_declined';
export const SELECTION_TUTO_STARTED = 'selection_tuto_started';
export const SELECTION_TUTO_COMPLETED = 'selection_tuto_completed';
export const SELECTION_TUTO_CANCELLED = 'selection_tuto_cancelled';
// search
export const SEARCH_FILTERS_OPENED = 'search_filters_opened';
export const SEARCH_FILTERS_SUBMITTED = 'search_filters_submitted';
export const SEARCH_FILTERS_CLEARED = 'search_filters_cleared';
export const SEARCH_FILTER_CHANGED = 'search_filter_changed';
export const SEARCH_MAP_BOUNDS_CHANGED = 'search_map_bounds_changed';
export const SEARCH_MAP_MARKER_CLICKED = 'search_map_marker_clicked';
export const SEARCH_3D_VISIT_OPENED = 'search_3d_visit_opened';
export const SHOW_3D_VISIT_OPENED = 'show_3d_visit_opened';
