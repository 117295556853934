var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "selection-modal-manager" },
        [
          _vm.place_added
            ? _c("place-added-to-selection-modal", {
                attrs: {
                  item: _vm.place_added,
                  close: _vm.closePlaceAddedModal,
                  dispatcher: _vm.dispatcher,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.animation
            ? _c("add-animation-to-selection-modal", {
                attrs: {
                  item: _vm.animation,
                  "selection-manager": _vm.selectionManager,
                  close: _vm.closeAnimationModal,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.menu
            ? _c("add-menu-to-selection-modal", {
                attrs: {
                  item: _vm.menu,
                  "selection-manager": _vm.selectionManager,
                  close: _vm.closeMenuModal,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.drink
            ? _c("add-drink-to-selection-modal", {
                attrs: {
                  item: _vm.drink,
                  "selection-manager": _vm.selectionManager,
                  close: _vm.closeDrinkModal,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.place
            ? _c("add-place-to-selection-modal", {
                attrs: {
                  item: _vm.place,
                  "selection-manager": _vm.selectionManager,
                  close: _vm.closePlaceModal,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.confirm
            ? _c("confirm-clear-selection-modal", {
                attrs: {
                  close: _vm.closeConfirmModal,
                  "selection-manager": _vm.selectionManager,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.auth
            ? _c("authentication-required-modal", {
                attrs: { close: _vm.closeAuthModal },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.places_only
            ? _c("only-places-selected-modal", {
                attrs: { close: _vm.closePlacesOnlyModal },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.event_brief
            ? _c("edit-event-brief-modal", {
                attrs: {
                  close: _vm.closeEventBriefModal,
                  "selection-manager": _vm.selectionManager,
                  device: _vm.device,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.selection_tour
            ? _c("launch-selection-tour-modal", {
                attrs: {
                  close: _vm.closeSelectionTourModal,
                  dispatcher: _vm.dispatcher,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.merge_selections
            ? _c("merge-selections-modal", {
                attrs: {
                  close: _vm.closeMergeSelections,
                  "selection-manager": _vm.selectionManager,
                  selection: _vm.previous_selection,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("modal-backdrop", { attrs: { close: _vm.closeAll } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }