<template>
  <div class="selection-modal authentication-required-modal">
    <div class="selection-modal-content">
      <button
        class="close-button"
        title="Fermer"
        @click="close()"
      >
        <icon icon="panier_picto_suppr" />
      </button>
      <div class="full">
        <div class="content">
          <h2 class="title-2 infos">
            Découvrez la plateforme
          </h2>
          <p class="text-1">
            1 minute pour découvrir les fonctionnalités de notre plateforme !
          </p>
          <button
            class="ct ct--button cta cta-clear cta-infos"
            @click="decline()"
          >
            Non merci
          </button>
          <button
            class="ct ct--button cta cta-infos"
            @click="start()"
          >
            Commencer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../../../icon/Icon.vue';
import { pushEventToDataLayer } from '../../../../helpers/data-layer-helper';
import { LAUNCH_SELECTION_TOUR } from '../../../../events/search-events';
import { SELECTION_TUTO_DECLINED, SELECTION_TUTO_STARTED } from '../../../../events/data-layer-events';

export default {
  name: 'LaunchSelectionTourModal',
  components: {
    Icon,
  },
  props: {
    close: {
      type: Function,
      required: true,
    },
    dispatcher: {
      type: Object,
      required: true,
    },
  },
  methods: {
    decline() {
      pushEventToDataLayer(SELECTION_TUTO_DECLINED);
      this.close();
    },
    start() {
      pushEventToDataLayer(SELECTION_TUTO_STARTED);
      setTimeout(() => this.dispatcher.emit(LAUNCH_SELECTION_TOUR), 50);
      this.close();
    },
  },
};
</script>
