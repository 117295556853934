import Vue from 'vue';
import App from './GenericModal.vue';

Vue.config.devtools = false;
Vue.config.productionTip = false;

const mount = (selector, dispatcher, device) => {
  new Vue({ render: (h) => h(App, { props: { dispatcher, device } }) }).$mount(selector);
};

export default mount;
