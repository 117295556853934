<template>
  <div
    v-if="place"
    class="selection-modal add-place-modal"
  >
    <div class="selection-modal-content">
      <button
        class="close-button"
        title="Fermer"
        @click="close()"
      >
        <icon icon="panier_picto_suppr" />
      </button>
      <!-- IMAGE -->
      <div class="visual">
        <div class="img-wrapper">
          <img
            :src="place.image.url"
            alt="Aperçu du lieu"
          >
        </div>
        <a
          v-if="item.context === 'list'"
          :href="place.uri"
          class="ct ct--button cta cta-infos"
          title="Voir les détails"
        >
          Voir les détails
        </a>
      </div>
      <!-- TEXT -->
      <div class="functional">
        <div class="content">
          <h2 class="title-2">
            Ajouter "{{ place.title }}" à votre sélection ?
          </h2>
          <form @submit.prevent="submit">
            <div class="place-infos">
              <div class="radio-wrapper">
                <p class="text-1">
                  Veuillez choisir le créneau horaire désiré :
                </p>
                <ul>
                  <li
                    v-for="choice in place.add_options.period.choices"
                    :key="choice.value"
                  >
                    <label>
                      <input
                        v-model="payload.period"
                        type="radio"
                        :value="choice.value"
                      >
                      <strong>{{ choice.label }}</strong>
                      (à partir de {{ choice.price|format_price }} € HT)
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-actions">
              <button
                class="ct ct--button cta cta-clear"
                @click="close()"
              >
                Annuler
              </button>
              <button
                type="submit"
                class="ct ct--button cta"
              >
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../../helpers/product-api';
import Icon from '../../../icon/Icon.vue';

export default {
  name: 'AddPlaceToSelectionModal',
  components: { Icon },
  filters: {
    format_price: function (value) {
      return Math.round(value / 100);
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    close: {
      type: Function,
      required: true,
    },
    selectionManager: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      place: null,
      payload: null,
    };
  },
  mounted: function () {
    api.getPlace(this.item.id).then((response) => {
      const options = response.data.data.add_options;
      const payload = {};
      if (options['period']) {
        const selection = this.selectionManager.getSelection();
        if (selection && selection.event && selection.event.period) {
          payload.period = selection.event.period.id;
        } else {
          payload.period = options['period']['choices'][0]['value'];
        }
      }
      this.payload = payload;
      this.place = response.data.data;
    });
  },
  methods: {
    submit: function () {
      const payload = { id: this.item.id, type: this.item.type, data: this.payload };
      this.selectionManager.addItemToSelection(payload).catch((error) => {
        if (error.response && error.response.status === 401) {
          return;
        }
        throw error; // if 400 => handle errors
      }).finally(() => {
        this.close();
      });
    },
  },
};
</script>
