var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selection-modal merge-selections-modal" }, [
    _c("div", { staticClass: "selection-modal-content" }, [
      _c(
        "button",
        {
          staticClass: "close-button",
          attrs: { title: "Fermer" },
          on: {
            click: function ($event) {
              return _vm.close()
            },
          },
        },
        [_c("icon", { attrs: { icon: "panier_picto_suppr" } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "full" }, [
        _c("div", { staticClass: "content" }, [
          _c("h2", { staticClass: "title-2" }, [
            _vm._v(
              "\n          Vous avez déjà une demande en cours !\n        "
            ),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "text-1" }, [
            _c("li", [_vm._v("Date : le " + _vm._s(_vm.selection.date))]),
            _vm._v(" "),
            _c("li", [_vm._v("Type : " + _vm._s(_vm.selection.type))]),
            _vm._v(" "),
            _c("li", [_vm._v("Invités : " + _vm._s(_vm.selection.guests))]),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "ct ct--button cta cta-clear",
              on: {
                click: function ($event) {
                  return _vm.brief()
                },
              },
            },
            [
              _c("icon", { attrs: { icon: "times-solid" } }),
              _vm._v("\n          Non, c'est une nouvelle demande\n        "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "ct ct--button cta",
              on: {
                click: function ($event) {
                  return _vm.merge()
                },
              },
            },
            [
              _c("icon", { attrs: { icon: "check-solid" } }),
              _vm._v("\n          Oui, c'est le même événement\n        "),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-1" }, [
      _c("strong", [_vm._v("S'agit-il du même événement ?")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }