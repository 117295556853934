import axios from 'axios';
import FOSRouting from '../modules/routing';

const api = {
  // selection
  get: () => axios.get(FOSRouting.generate('api_selection_get', { ts: Date.now() })),
  // selection items
  addItem: (item) => axios.post(FOSRouting.generate('api_selection_add_item'), item),
  removeItem: (item) => axios.delete(FOSRouting.generate('api_selection_remove_item', { id: item.id })),
  deleteItems: () => axios.delete(FOSRouting.generate('api_selection_delete_items')),
  // recent selection
  getPreviousSelection: () => axios.get(FOSRouting.generate('api_selection_previous_get', { ts: Date.now() })),
  mergeSelections: () => axios.post(FOSRouting.generate('api_selection_previous_merge')),
  // brief
  getBriefStep1Values: () => axios.get(FOSRouting.generate('api_selection_get_brief_step_1')),
  getBriefStep2Values: () => axios.get(FOSRouting.generate('api_selection_get_brief_step_2')),
  getBriefStep3Values: () => axios.get(FOSRouting.generate('api_selection_get_brief_step_3')),
  updateBriefStep1Event: (payload) => axios.put(FOSRouting.generate('api_selection_update_brief_step_1'), payload),
  updateBriefStep2Event: (payload) => axios.put(FOSRouting.generate('api_selection_update_brief_step_2'), payload),
  updateBriefStep3Event: (payload) => axios.put(FOSRouting.generate('api_selection_update_brief_step_3'), payload),
};

export default api;
