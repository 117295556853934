<template>
  <form
    class="generic-form"
    @submit.prevent="submit"
  >
    <div
      v-if="loaded === false"
      class="form-body-placeholder"
    />
    <div
      v-if="loaded === true"
      class="form-body"
    >
      <div class="form-header">
        <h2 class="title">
          <span>Indiquez-nous les modalités</span> de votre événement
        </h2>
        <p class="subtitle">
          <span>Afin de valider la disponibilité et le prix</span> nous avons besoin d’en savoir plus
        </p>
      </div>
      <h3 class="title-4">
        Général
      </h3>
      <event-brief-step-form-errors
        v-if="errors"
        :dismiss="dismissErrors"
        :errors="errors"
      />
      <div class="form-row">
        <!-- TITRE -->
        <div
          class="form-group form-title"
          :class="{ 'has-errors': isEventTitleFieldInvalid }"
        >
          <label
            class="required"
            for="edit-event-title"
          >Donnez un titre à votre événement</label>
          <input
            id="edit-event-title"
            v-model="payload.title"
            type="text"
            class="form-control"
            maxlength="50"
            required
          >
          <div
            v-if="isEventTitleFieldInvalid"
            class="form-error"
          >
            {{ inlineerrors.title }}
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <!-- RÉGION -->
          <div
            class="form-group form-region"
            :class="{ 'has-errors': isRegionFieldInvalid }"
          >
            <label
              class="required"
              for="edit-event-region"
            >Dans quelle région se deroulera votre événement ?</label>
            <select
              v-model="payload.region"
              class="form-control"
              required
            >
              <option
                v-for="region in values.regions"
                :key="region.id"
                :value="region.id"
              >
                {{ region.label }}
              </option>
            </select>
          </div>
        </div>
        <!-- TYPE D'ÉVÉNEMENT -->
        <div class="form-col">
          <div
            class="form-group form-category"
            :class="{ 'has-errors': isEventCategoryFieldInvalid }"
          >
            <label
              class="required"
              for="edit-event-category"
            >Quel type d'événements organisez vous ?</label>
            <multiselect
              id="edit-event-category"
              ref="category-multiselect"
              v-model="payload.category"
              :options="values.categories"
              :close-on-select="true"
              :searchable="false"
              :hide-selected="false"
              :multiple="false"
              label="name"
              track-by="id"
              placeholder=""
              selected-label="✓"
              select-label=""
              deselect-label=""
              class="full-mobile"
              @select="eventCategorySelected"
            >
              <template slot="beforeList">
                <div class="list-title">
                  Type d'événement
                  <button
                    type="button"
                    class="close-button"
                    @click="deactivateMultiselect('category-multiselect')"
                  >
                    <icon icon="panier_picto_suppr" />
                  </button>
                </div>
              </template>
              <template
                slot="singleLabel"
                slot-scope="props"
              >
                <icon :icon="'event_category_' + props.option.reference" />
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.name }}
                  </span>
                </span>
              </template>
              <template
                slot="option"
                slot-scope="props"
              >
                <icon :icon="'event_category_' + props.option.reference" />
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.name }}
                  </span>
                </span>
              </template>
            </multiselect>
            <div
              v-if="isEventCategoryFieldInvalid"
              class="form-error"
            >
              {{ inlineerrors.category }}
            </div>
          </div>
        </div>
      </div>
      <hr>
      <!-- DATE -->
      <div
        class="form-row form-row-date"
        :class="{ 'has-errors': isDateGroupInvalid }"
      >
        <label
          class="required"
          for="edit-event-date"
        >A quelle date aura lieu votre événement ?</label>
        <div class="fields">
          <!-- -->
          <div
            ref="calendar-holder"
            class="form-group form-date"
            :class="{'has-errors': false}"
          >
            <icon icon="filtres_dates" />
            <flat-pickr
              id="edit-event-date"
              v-model="payload.date"
              :config="config.flatpickr"
              class="form-control"
              required
            />
          </div>
          <!-- -->
          <div class="form-group form-time form-time-start">
            <select
              v-model="payload.startTime"
              class="form-control"
              required
            >
              <option
                v-for="time in values.times"
                :key="time.value"
                :value="time.value"
              >
                {{ time.label }}
              </option>
            </select>
          </div>
          <div class="form-group form-time form-time-end">
            <select
              v-model="payload.endTime"
              class="form-control"
              required
            >
              <option
                v-for="time in values.times"
                :key="time.value"
                :value="time.value"
              >
                {{ time.label }}
              </option>
            </select>
          </div>
        </div>
        <!-- ERRORS -->
        <div
          v-if="isDateGroupInvalid"
          class="form-error"
        >
          {{ inlineerrors.date }}
        </div>
        <!-- -->
        <div class="more">
          <label class="checkbox checkbox-custom">
            <input
              v-model="payload.flexibleDate"
              type="checkbox"
            >
            <span>Date flexible</span>
          </label>
          <label class="checkbox checkbox-custom">
            <input
              v-model="payload.flexibleHours"
              type="checkbox"
            >
            <span>Horaires flexibles</span>
          </label>
          <label class="checkbox checkbox-custom">
            <input
              v-model="payload.multiDays"
              type="checkbox"
            >
            <span>Sur plusieurs jours</span>
          </label>
        </div>
      </div>
      <hr>
      <!-- BUDGET -->
      <div
        class="form-row form-row-budget"
        :class="{ 'has-errors': isBudgetFieldInvalid }"
      >
        <label
          class="required"
          for="edit-event-budget"
        >Quel est votre budget global ?</label>
        <div class="form-group form-budget">
          <icon icon="euro-sign-solid" />
          <select
            v-model="payload.budget"
            class="form-control"
            required
          >
            <option
              v-for="budget in values.budgets"
              :key="budget.value"
              :value="budget.value"
            >
              {{ budget.label }}
            </option>
          </select>
          <div
            v-if="isBudgetFieldInvalid"
            class="form-error"
          >
            {{ inlineerrors.budget }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-actions">
      <button
        type="submit"
        class="ct ct--button cta"
      >
        Organisation (2/3)
        <icon icon="arrow-right-solid" />
      </button>
    </div>
  </form>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { French } from 'flatpickr/dist/l10n/fr';
import Multiselect from 'vue-multiselect';
import api from '../../../../../helpers/selection-api';
import Icon from '../../../../icon/Icon.vue';
import EventBriefStepFormErrors from './EventBriefFormErrors.vue';

export default {
  name: 'EventBriefStep1Form',
  components: {
    Icon,
    flatPickr,
    Multiselect,
    EventBriefStepFormErrors,
  },
  props: {
    selectionManager: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      errors: null,
      inlineerrors: {},
      values: {
        regions: [],
        categories: [],
        times: [],
        budgets: [],
      },
      config: {
        flatpickr: {
          altInput: true,
          altFormat: 'd/m/Y',
          appendTo: this.$refs['calendar-holder'],
          dateFormat: 'd-m-Y',
          locale: French,
          minDate: 'today',
          static: true,
          disableMobile: true,
          position: 'above',
          monthSelectorType: 'static',
        },
      },
      payload: {
        title: null,
        region: null,
        category: null,
        date: null,
        multiDays: null,
        flexibleDate: null,
        flexibleHours: null,
        startTime: null,
        endTime: null,
        budget: null,
      },
    };
  },
  computed: {
    isEventTitleFieldInvalid() {
      return !!this.inlineerrors.title;
    },
    isRegionFieldInvalid() {
      return !!this.inlineerrors.region;
    },
    isEventCategoryFieldInvalid() {
      return !!this.inlineerrors.category;
    },
    isDateGroupInvalid() {
      return !!this.inlineerrors.date;
    },
    isBudgetFieldInvalid() {
      return !!this.inlineerrors.budget;
    },
  },
  mounted: function () {
    Promise.all([api.get(), api.getBriefStep1Values()]).then(([selection, values]) => {
      const event = selection.data.selection.event;
      this.values = { ...values.data.data };
      this.payload = {
        title: event.title ? event.title : `Mon événement${event.date ? ' du ' + event.date.replace(/\//g, '-') : ''}`,
        region: event.region ? event.region.id : null,
        category: event.category,
        date: event.date ? event.date.replace(/\//g, '-') : null,
        flexibleDate: event.flexibleDate,
        flexibleHours: event.flexibleHours,
        multiDays: event.multiDays,
        startTime: event.startTime ?? 10,
        endTime: event.endTime ?? 18,
        budget: event.budget ?? null,
      };
      this.loaded = true;
    });
  },
  methods: {
    eventCategorySelected(selectedOption) {
      if (!(selectedOption && selectedOption.defaultStartTime && selectedOption.defaultEndTime)) {
        return;
      }
      this.payload.startTime = selectedOption.defaultStartTime;
      this.payload.endTime = selectedOption.defaultEndTime;
    },
    deactivateMultiselect(ref) {
      this.$refs[ref].deactivate();
    },
    submit() {
      // reset validation state
      this.inlineerrors = {};
      // validation inline
      if (this.payload.title.length > 50) {
        this.inlineerrors = {
          ...this.inlineerrors,
          title: 'Le nom de votre événement ne peut pas dépasser 50 caractères.',
        };
        return;
      }
      if (this.payload.region === null) {
        this.inlineerrors = { ...this.inlineerrors, region: 'Merci de renseigner une région' };
        return;
      }
      if (this.payload.category === null) {
        this.inlineerrors = { ...this.inlineerrors, category: 'Merci de renseigner un type d\'événement' };
        return;
      }
      if (this.payload.date === null || this.payload.startTime === null || this.payload.endTime === null) {
        this.inlineerrors = {
          ...this.inlineerrors,
          date: 'Merci de saisir la date et les horaires de votre événement',
        };
        return;
      }
      if (this.payload.budget === null) {
        this.inlineerrors = { ...this.inlineerrors, budget: 'Merci de saisir un budget pour votre événement' };
        return;
      }
      // payload preparation
      const payload = { ...this.payload };
      payload.category = payload.category ? payload.category.id : null;
      // call api
      this.selectionManager.updateBriefStep1(payload).then(() => {
        this.$emit('submitted'); // step1submitted
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data.data;
        }
      });
    },
    dismissErrors() {
      this.errors = null;
    },
  },
};
</script>
