class States {
  constructor() {
    this.width = document.documentElement.clientWidth || window.innerWidth;
    this.height = document.documentElement.clientHeight || window.innerHeight;
    this.passive = this._detectPassiveMode();
    this.dom = {};
  }

  _detectPassiveMode() {
    let passive = false;
    try {
      const options = Object.defineProperty({}, 'passive', {
        get: () => {
          passive = true;
        },
      });
      window.addEventListener('test', null, options);
    } catch (e) {
      // continue regardless of error
    }
    return passive;
  }
}

export default new States();
