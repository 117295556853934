<template>
  <div class="selection-modal place-added-modal">
    <div class="selection-modal-content">
      <button
        class="close-button"
        title="Fermer"
        @click="close()"
      >
        <icon icon="panier_picto_suppr" />
      </button>
      <!-- IMAGE -->
      <div class="visual">
        <div class="img-wrapper">
          <img
            :src="item.item_image.url"
            alt="Aperçu du lieu"
          >
        </div>
      </div>
      <!-- TEXT -->
      <div class="functional">
        <div class="content">
          <h2 class="title-2">
            {{ item.item_title }} a bien été ajouté à votre sélection
          </h2>
          <p class="text-1">
            Vous pouvez continuer de parcourir notre catalogue et ajouter d'autres lieux et prestations à votre
            sélection
            pour votre événement. Ou vous pouvez demander la disponibilité de ce lieu au propriétaire et
            commencer tout de suite l'organisation de votre événement.
          </p>
          <button
            class="ct ct--button cta cta-clear"
            @click="close()"
          >
            Poursuivre ma sélection
          </button>
          <button
            class="ct ct--button cta"
            @click="checkSelection"
          >
            J'ai terminé ma sélection
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { REQUEST_QUOTATION } from '../../../../messages/selection-messages';
import Icon from '../../../icon/Icon.vue';

export default {
  name: 'PlaceAddedToSelectionModal',
  components: { Icon },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    close: {
      type: Function,
      required: true,
    },
    dispatcher: {
      type: Object,
      required: true,
    },
  },
  methods: {
    checkSelection: function () {
      this.dispatcher.emit(REQUEST_QUOTATION);
      this.close();
    },
  },
};
</script>
