import { AUTHENTICATION_REQUIRED } from '../events/auth-events';
import { SELECTION_OPEN_BRIEF } from '../events/data-layer-events';
import { pushEventToDataLayer } from './data-layer-helper';
import {
  buildItemAddedToSelectionNofication,
  buildItemRemovedFromSelectionNofication,
} from './notification-factory';
import api from './selection-api';
import {
  SELECTION_CLEARED,
  SELECTION_ITEM_ADDED,
  SELECTION_ITEM_REMOVED,
  SELECTION_UPDATED,
} from '../events/selection-events';
import {
  CONFIGURE_ITEM_FOR_SELECTION,
  ADD_ITEM_TO_SELECTION,
  REMOVE_ITEM_FROM_SELECTION,
  REQUEST_QUOTATION,
  SHOW_REQUEST_QUOTATION_MODAL,
  SHOW_MERGE_SELECTIONS_MODAL,
} from '../messages/selection-messages';

export default class SelectionManager {
  constructor(notifier, dispatcher, device) {
    this.notifier = notifier;
    this.dispatcher = dispatcher;
    this.setSelection(window.App.Selection);
    this.setSearchContext({});
    this.isMobile = device === 'mobile';
  }

  // state management section

  setSelection(selection) {
    this.selection = selection || {
      event: {},
      items: [],
    };
  }

  getSelection() {
    return this.selection;
  }

  getSelectionItem(type, id) {
    return this.selection.items.find((item) => item.item_type === type && item.item_id === id);
  }

  addItemToSelection(item) {
    return new Promise((resolve, reject) => {
      api.addItem(item).then((response) => {
        this.setSelection(response.data.selection);
        const isFirst = this.selection.items.filter((item) => item.item_type === 'place').length === 1;
        if (isFirst === false) {
          this.notifier.notify(buildItemAddedToSelectionNofication(response.data.item_added, this.isMobile));
        }
        this.dispatcher.emit(SELECTION_ITEM_ADDED, response.data.item_added);
        this.dispatcher.emit(SELECTION_UPDATED, this.selection);
        resolve(response);
      }).catch((error) => {
        if (error.response && error.response.status === 401) {
          this.dispatcher.emit(AUTHENTICATION_REQUIRED);
        }
        if (error.response && error.response.status === 400) {
          // todo : introduce a custom error message / object system => authentication, validation...
          // reject({errors:[...]})
        }
        reject(error);
      });
    });
  }

  removeItemFromSelection(item, withNotification = true) {
    return new Promise((resolve, reject) => {
      api.removeItem(item).then((response) => {
        this.setSelection(response.data.selection);
        if (withNotification) {
          this.notifier.notify(buildItemRemovedFromSelectionNofication(item));
        }
        this.dispatcher.emit(SELECTION_ITEM_REMOVED, this.selection);
        this.dispatcher.emit(SELECTION_UPDATED, this.selection);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  handleRequestQuotation() {
    return new Promise((resolve, reject) => {
      api.getPreviousSelection().then((response) => {
        if (response.data.selection === null) { // no previous selection : show brief modal
          this.requestQuotation();
        } else { // previous selection : show merge selections modal
          this.dispatcher.emit(SHOW_MERGE_SELECTIONS_MODAL, response.data.selection);
        }
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  mergeSelections() {
    return new Promise((resolve, reject) => {
      api.mergeSelections().then((response) => {
        resolve(response.data.redirect);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  requestQuotation() {
    pushEventToDataLayer(SELECTION_OPEN_BRIEF);
    this.dispatcher.emit(SHOW_REQUEST_QUOTATION_MODAL);
  }

  clearSelection() {
    return new Promise((resolve, reject) => {
      api.deleteItems().then((response) => {
        this.setSelection(response.data.selection);
        this.dispatcher.emit(SELECTION_CLEARED, this.selection);
        this.dispatcher.emit(SELECTION_UPDATED, this.selection);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  refreshSelection() {
    return new Promise((resolve, reject) => {
      api.get().then((response) => {
        this.setSelection(response.data.selection);
        this.dispatcher.emit(SELECTION_UPDATED, this.selection);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateBriefStep1(payload) {
    return new Promise((resolve, reject) => {
      api.updateBriefStep1Event(payload).then((response) => {
        this.setSelection(response.data.selection);
        this.dispatcher.emit(SELECTION_UPDATED, this.selection);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateBriefStep2(payload) {
    return new Promise((resolve, reject) => {
      api.updateBriefStep2Event(payload).then((response) => {
        this.setSelection(response.data.selection);
        this.dispatcher.emit(SELECTION_UPDATED, this.selection);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateBriefStep3(payload) {
    return new Promise((resolve, reject) => {
      api.updateBriefStep3Event(payload).then((response) => {
        this.setSelection(response.data.selection);
        this.dispatcher.emit(SELECTION_UPDATED, this.selection);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  // search context

  setSearchContext(context) {
    this.searchContext = context || {};
  }

  // ui management section

  init() {
    // initial ui rendering
    this.handleUI();
    // refresh ui on state change
    this.dispatcher.on(SELECTION_UPDATED, () => {
      this.handleUI();
    });
    // handle external add item to selection command
    this.dispatcher.on(ADD_ITEM_TO_SELECTION, (item) => {
      this.addItemToSelection(item).catch((error) => {
        if (error.response && error.response.status === 401) {
          return;
        }
        throw error; // if 400 => handle errors
      });
    });
    // handle externa remove item to selection command - no notification on remove from mobile sidebar
    this.dispatcher.on(REMOVE_ITEM_FROM_SELECTION, (item) => this.removeItemFromSelection(item, this.isMobile));
    // handle request quotation
    this.dispatcher.on(REQUEST_QUOTATION, () => this.handleRequestQuotation());
    // refresh state on history / browser back navigation
    window.addEventListener('pageshow', (event) => {
      const historyTraversal = event.persisted || (typeof window.performance != 'undefined' && window.performance.navigation.type === 2);
      if (historyTraversal) {
        this.refreshSelection();
      }
    });
  }

  handleUI() {
    this.handleSelectedItemUIElements();
    this.handleAddRemoveItemUIElements();
  }

  handleSelectedItemUIElements() {
    const elements = document.querySelectorAll('.ct--item-result');
    elements.forEach((element) => this._handleSelectedItemUIElement(element));
  }

  handleAddRemoveItemUIElements() {
    const elements = document.querySelectorAll('.ct--item-result, .js-show-selection-widget-holder');
    elements.forEach((element) => this._handleAddRemoveItemUIElement(element));
  }

  _handleSelectedItemUIElement(element) {
    const itemId = parseInt(element.getAttribute('data-id'));
    const itemType = element.getAttribute('data-type');
    const selectionItem = this.getSelectionItem(itemType, itemId);
    if (typeof selectionItem !== 'undefined') {
      element.classList.add('selected');
    } else {
      element.classList.remove('selected');
    }
  }

  _handleAddRemoveItemUIElement(element) {
    const container = element.classList.contains('js-selection-helper') ? element : element.querySelector('.js-selection-helper');

    if (container === null) {
      return;
    }

    // hack for "list" + "show" ui
    const elementWrapper = element.classList.contains('js-selection-helper') ? element : element.querySelector('.js-selection-helper');

    const itemId = parseInt(elementWrapper.getAttribute('data-id'));
    const itemType = elementWrapper.getAttribute('data-type');
    const itemSubType = elementWrapper.getAttribute('data-sub-type');
    const context = elementWrapper.getAttribute('data-context');

    const selectionItem = this.getSelectionItem(itemType, itemId);
    const isItemSelected = typeof selectionItem !== 'undefined';

    // add button

    const addButton = container.querySelector('.js-add-to-selection');

    addButton.onclick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const item = { id: itemId, type: itemType };
      this.dispatcher.emit(CONFIGURE_ITEM_FOR_SELECTION, { ...item, subType: itemSubType, context });
    };

    // remove button

    const removeButton = container.querySelector('.js-remove-from-selection');

    removeButton.onclick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.removeItemFromSelection(selectionItem);
    };

    // ui update

    if (isItemSelected === true) {
      addButton.classList.add('hidden');
      removeButton.classList.remove('hidden');
    } else {
      addButton.classList.remove('hidden');
      removeButton.classList.add('hidden');
    }
  }

  enterDemoMode({ id, title, price, region = 'Paris', period = 'Après-midi' }) {
    this.setSelection({
      id: 1,
      event: {
        region: { id: 1, label: region },
      },
      items: [{
        id: 1,
        item_id: 19,
        item_title: title,
        item_price: price,
        item_type: 'place',
        item_sub_type: 'place',
        item_options: {
          period: { label: period, value: 4 },
        },
      }],
    });
    this.dispatcher.emit(SELECTION_UPDATED, this.selection);
  }

  exitDemoMode() {
    this.refreshSelection();
  }
}
