import { buildTrackingEvent } from './data-layer-event-factory';

export const pushToDataLayer = (data) => {
  if (Array.isArray(data) === false) {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  data.forEach((datum) => {
    window.dataLayer.push(datum);
  });
};

export const pushEventToDataLayer = (eventName, eventPayload = {}) => {
  console.log('[DL PUSH EVENT]', eventName, eventPayload);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...eventPayload,
  });
};

export const pushTrackingEventToDataLayer = (eventName, eventPayload = {}) => {
  const event = buildTrackingEvent(eventName, eventPayload);
  if (!event) {
    return;
  }
  console.log('[DL PUSH TRACK EVENT] ', eventName, eventPayload);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'eventTracking',
    ...event,
  });
};
