<template>
  <div
    v-if="selection"
    :class="{ 'is-empty': selection.items.length === 0 }"
    style="height: 100%;"
  >
    <div
      class="empty-container"
      :class="{'hidden': selection.items.length !== 0}"
    >
      <span class="empty-title">Vous n'avez encore <br>rien selectionné</span>
      <p class="empty-description">
        Parcourez nos offres pour ajouter un lieu et des services à votre événement.
      </p>
    </div>
    <div
      class="basket-container"
      :class="{'hidden': selection.items.length === 0}"
    >
      <div class="basket-result">
        <div
          ref="wrapper"
          class="basket-items"
        >
          <div
            v-for="section in sections"
            :key="section.type"
          >
            <div
              :ref="'type-' + section.type"
              class="basket-item"
              :class="{ 'hidden': selection.items.filter(item => item.item_sub_type === section.type).length === 0 }"
            >
              <div class="headline">
                <div class="col">
                  <span class="type basket-1">
                    <icon :icon="section.icon" />
                    {{ section.label }}
                  </span>
                </div>
              </div>
              <div class="details">
                <ul>
                  <transition-group
                    name="list"
                    @before-enter="beforeEnter"
                  >
                    <li
                      v-for="item in selection.items.filter(item => item.item_sub_type === section.type)"
                      :key="item.id"
                      class="filter-2"
                      :data-type="'type-' + section.type"
                    >
                      <div class="left">
                        <div class="item-name">
                          {{ item.item_title }}
                        </div>
                        <!-- CONFIGURATION OPTIONS -->
                        <div
                          v-if="item.item_options.quantity || item.item_options.duration || item.item_options.pack || item.item_options.period"
                          class="item-options"
                          style="margin: 3px 0;"
                        >
                          <div
                            v-if="item.item_options.quantity"
                            class="item-quantity"
                          >
                            x{{ item.item_options.quantity.label }}
                          </div>
                          <div
                            v-if="item.item_options.duration"
                            class="item-duration"
                          >
                            {{ item.item_options.duration.label }}h
                          </div>
                          <div
                            v-if="item.item_options.pack"
                            class="item-pack"
                          >
                            {{ item.item_options.pack.label|truncate(28) }}
                          </div>
                          <div
                            v-if="item.item_options.period"
                            class="item-period"
                          >
                            {{ item.item_options.period.label }}
                          </div>
                        </div>
                        <!-- PRICE -->
                        <div
                          class="item-price"
                          style="font-weight: bold; margin-bottom: 3px;"
                        >
                          {{ item.item_price }} <span v-if="section.type === 'place'">*</span>
                        </div>
                      </div>
                      <!-- ACTIONS -->
                      <div class="right">
                        <button
                          v-if="!readOnly"
                          class="remove-item"
                          @click="removeItem(item)"
                        >
                          <icon icon="panier_picto_suppr" />
                        </button>
                      </div>
                    </li>
                  </transition-group>
                </ul>
              </div>
              <div
                v-if="section.type === 'place'"
                class="additional-infos"
              >
                * les prix seront validés suite à la demande de disponibilités et sont ici estimatifs.
              </div>
            </div>
          </div>
          <div class="basket-item">
            <div class="headline">
              <div class="col">
                <span class="type basket-1">
                  Services Annexes
                </span>
              </div>
            </div>
            <div class="details">
              <ul>
                <li>
                  <p class="additional-infos">
                    Des coûts supplémentaires à la location s’ajouteront au prix estimatif : <br>
                    - Frais de gestion<br>
                    - Forfait ménage<br>
                    - Snapper (coordinateur d’événement)
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="!readOnly"
            class="clear-selection"
          >
            <button
              class="clear-selection-button"
              title="Vider les éléments de ma sélection"
              @click="clearSelection"
            >
              <icon icon="selection-trash" />
              Vider la sélection
            </button>
          </div>
        </div>
      </div>
      <div class="total-basket">
        <button
          class="add-to-selection btn-submit-basket-new"
          @click="checkSelection"
        >
          DEMANDER LA DISPONIBILITÉ <br>ET LE PRIX
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { SELECTION_UPDATED } from '../../../events/selection-events';
import {
  CONFIRM_CLEAR_SELECTION,
  REMOVE_ITEM_FROM_SELECTION,
  REQUEST_QUOTATION,
} from '../../../messages/selection-messages';
import Icon from '../../icon/Icon.vue';

export default {
  components: { Icon },
  filters: {
    truncate: function (value, length = 32) {
      if (typeof value !== 'string') {
        return '';
      }
      value = value.trim();
      if (value.length <= length) {
        return value;
      }
      return value.substr(0, length).trim() + '...';
    },
  },
  props: {
    dispatcher: {
      type: Object,
      required: true,
    },
    readOnly: Boolean,
  },
  data() {
    return {
      sections: [
        {
          label: 'Lieux',
          icon: 'panier_picto_lieu',
          type: 'place',
        },
        {
          label: 'Traiteurs',
          icon: 'panier_picto_traiteur',
          type: 'menu',
        },
        {
          label: 'Boissons',
          icon: 'panier_picto_boissons',
          type: 'drink',
        },
        {
          label: 'Animations',
          icon: 'panier_picto_anim',
          type: 'option',
        },
        {
          label: 'Matériel',
          icon: 'panier_picto_matoss',
          type: 'equipment',
        },
        {
          label: 'Staff',
          icon: 'panier_picto_staff',
          type: 'reception',
        },
      ],
      selection: window.App.Selection || {
        event: {},
        items: [],
      },
    };
  },
  mounted() {
    this.dispatcher.on(SELECTION_UPDATED, (selection) => this.selection = selection);
  },
  methods: {
    clearSelection: function () {
      this.dispatcher.emit(CONFIRM_CLEAR_SELECTION);
    },
    checkSelection: function () {
      /* DISABLED FOR NOW
        if (this.selection.items.filter(item => item.item_type !== 'place').length === 0) {
          event.preventDefault();
          this.dispatcher.emit(SHOW_PLACES_ONLY_MODAL);
        }
         */
      this.dispatcher.emit(REQUEST_QUOTATION);
    },
    removeItem: function (item) {
      this.dispatcher.emit(REMOVE_ITEM_FROM_SELECTION, item);
    },
    beforeEnter: function (el) {
      this.$refs.wrapper.scrollTop = this.$refs[el.getAttribute('data-type')][0].offsetTop - 10;
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}

.list-enter-active {
  transition: opacity .5s;
}

.list-enter {
  opacity: 0;
}
</style>
