<template>
  <div class="selection-modal only-places-selected-modal">
    <div class="selection-modal-content">
      <button
        class="close-button"
        title="Fermer"
        @click="close()"
      >
        <icon icon="panier_picto_suppr" />
      </button>
      <div class="full">
        <div class="content">
          <h2 class="title-2">
            Ajoutez de l'émotion à votre événement
          </h2>
          <p class="text-1">
            Vous pouvez consulter notre catalogue de traiteurs et d'animations sélectionnés par nos soins et
            les ajouter facilement à votre sélection.
          </p>
          <a
            :href="featuredMenusUrl"
            class="ct ct--button cta cta-clear"
          >
            Consulter les traiteurs
          </a>
          <a
            :href="requestQuotationUrl"
            class="ct ct--button cta"
          >
            Valider la disponibilité et le prix
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FOSRouting from '../../../../modules/routing';
import Icon from '../../../icon/Icon.vue';

export default {
  name: 'OnlyPlacesSelectedModal',
  components: {
    Icon,
  },
  props: {
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      featuredMenusUrl: FOSRouting.generate('snp_caterer_menu_index', { _region: 'paris' }),
      requestQuotationUrl: FOSRouting.generate('request_quotation'),
    };
  },
};
</script>
