/**
 * Finds the closest parent that matches a selector.
 *
 * @param  {Element} element
 * @param  {string}  selector
 *
 * @return {Function}
 */
export default function closest(element, selector) {
  const DOCUMENT_NODE_TYPE = 9;
  if ((element && element.nodeType !== DOCUMENT_NODE_TYPE && typeof element.nodeType !== 'undefined') === false) {
    return;
  }
  return element.closest(selector);
}
