var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "generic-modal-manager", class: _vm.shown_class },
        [
          _vm.visible_backdrop
            ? _c("modal-backdrop", { attrs: { close: _vm.closeAll } })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }