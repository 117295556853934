var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selection-modal authentication-required-modal" },
    [
      _c("div", { staticClass: "selection-modal-content" }, [
        _c(
          "button",
          {
            staticClass: "close-button",
            attrs: { title: "Fermer" },
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_c("icon", { attrs: { icon: "panier_picto_suppr" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "full" }, [
          _c("div", { staticClass: "content" }, [
            _c("h2", { staticClass: "title-2" }, [
              _vm._v("\n          Authentifiez-vous\n        "),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "ct ct--button cta cta-clear",
                attrs: { href: _vm.loginUrl },
              },
              [_vm._v("\n          Connexion\n        ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "ct ct--button cta",
                attrs: { href: _vm.registerUrl },
              },
              [_vm._v("\n          Inscription\n        ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-1" }, [
      _vm._v(
        "\n          Pour continuer vous devez vous authentifier. La création d’un compte ne prend que quelques\n          secondes."
      ),
      _c("br"),
      _vm._v(
        "\n          Si vous avez déjà un compte, connectez-vous.\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }