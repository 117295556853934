import axios from 'axios';
import FOSRouting from '../modules/routing';

const api = {
  getOption: (id) => axios.get(FOSRouting.generate('api_product_get_option', { id })),
  getMenu: (id) => axios.get(FOSRouting.generate('api_product_get_menu', { id })),
  getDrink: (id) => axios.get(FOSRouting.generate('api_product_get_drink', { id })),
  getPlace: (id) => axios.get(FOSRouting.generate('api_product_get_place', { id })),
};

export default api;
