<template>
  <strong class="quantity">{{ items.length }}</strong>
</template>

<script>
import { SELECTION_UPDATED } from '../../../events/selection-events';

export default {
  props: {
    dispatcher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: window.App.Selection ? window.App.Selection.items : [],
    };
  },
  mounted() {
    this.dispatcher.on(SELECTION_UPDATED, (selection) => this.items = selection.items || []);
  },
};
</script>
