var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "generic-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _vm.loaded === false
        ? _c("div", { staticClass: "form-body-placeholder" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded === true
        ? _c(
            "div",
            { staticClass: "form-body" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("h3", { staticClass: "title-4" }, [
                _vm._v("\n      Général\n    "),
              ]),
              _vm._v(" "),
              _vm.errors
                ? _c("event-brief-step-form-errors", {
                    attrs: { dismiss: _vm.dismissErrors, errors: _vm.errors },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group form-title",
                    class: { "has-errors": _vm.isEventTitleFieldInvalid },
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "required",
                        attrs: { for: "edit-event-title" },
                      },
                      [_vm._v("Donnez un titre à votre événement")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.payload.title,
                          expression: "payload.title",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "edit-event-title",
                        type: "text",
                        maxlength: "50",
                        required: "",
                      },
                      domProps: { value: _vm.payload.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.payload, "title", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.isEventTitleFieldInvalid
                      ? _c("div", { staticClass: "form-error" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.inlineerrors.title) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-col" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-group form-region",
                      class: { "has-errors": _vm.isRegionFieldInvalid },
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "required",
                          attrs: { for: "edit-event-region" },
                        },
                        [
                          _vm._v(
                            "Dans quelle région se deroulera votre événement ?"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.payload.region,
                              expression: "payload.region",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.payload,
                                "region",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.values.regions, function (region) {
                          return _c(
                            "option",
                            { key: region.id, domProps: { value: region.id } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(region.label) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-col" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-group form-category",
                      class: { "has-errors": _vm.isEventCategoryFieldInvalid },
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "required",
                          attrs: { for: "edit-event-category" },
                        },
                        [_vm._v("Quel type d'événements organisez vous ?")]
                      ),
                      _vm._v(" "),
                      _c(
                        "multiselect",
                        {
                          ref: "category-multiselect",
                          staticClass: "full-mobile",
                          attrs: {
                            id: "edit-event-category",
                            options: _vm.values.categories,
                            "close-on-select": true,
                            searchable: false,
                            "hide-selected": false,
                            multiple: false,
                            label: "name",
                            "track-by": "id",
                            placeholder: "",
                            "selected-label": "✓",
                            "select-label": "",
                            "deselect-label": "",
                          },
                          on: { select: _vm.eventCategorySelected },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "singleLabel",
                                fn: function (props) {
                                  return [
                                    _c("icon", {
                                      attrs: {
                                        icon:
                                          "event_category_" +
                                          props.option.reference,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "option__desc" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "option__title" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(props.option.name) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "option",
                                fn: function (props) {
                                  return [
                                    _c("icon", {
                                      attrs: {
                                        icon:
                                          "event_category_" +
                                          props.option.reference,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "option__desc" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "option__title" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(props.option.name) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2762958678
                          ),
                          model: {
                            value: _vm.payload.category,
                            callback: function ($$v) {
                              _vm.$set(_vm.payload, "category", $$v)
                            },
                            expression: "payload.category",
                          },
                        },
                        [
                          _c("template", { slot: "beforeList" }, [
                            _c("div", { staticClass: "list-title" }, [
                              _vm._v(
                                "\n                Type d'événement\n                "
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "close-button",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deactivateMultiselect(
                                        "category-multiselect"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("icon", {
                                    attrs: { icon: "panier_picto_suppr" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.isEventCategoryFieldInvalid
                        ? _c("div", { staticClass: "form-error" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.inlineerrors.category) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-row form-row-date",
                  class: { "has-errors": _vm.isDateGroupInvalid },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "required",
                      attrs: { for: "edit-event-date" },
                    },
                    [_vm._v("A quelle date aura lieu votre événement ?")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "fields" }, [
                    _c(
                      "div",
                      {
                        ref: "calendar-holder",
                        staticClass: "form-group form-date",
                        class: { "has-errors": false },
                      },
                      [
                        _c("icon", { attrs: { icon: "filtres_dates" } }),
                        _vm._v(" "),
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          attrs: {
                            id: "edit-event-date",
                            config: _vm.config.flatpickr,
                            required: "",
                          },
                          model: {
                            value: _vm.payload.date,
                            callback: function ($$v) {
                              _vm.$set(_vm.payload, "date", $$v)
                            },
                            expression: "payload.date",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group form-time form-time-start" },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.payload.startTime,
                                expression: "payload.startTime",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { required: "" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.payload,
                                  "startTime",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.values.times, function (time) {
                            return _c(
                              "option",
                              {
                                key: time.value,
                                domProps: { value: time.value },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(time.label) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group form-time form-time-end" },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.payload.endTime,
                                expression: "payload.endTime",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { required: "" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.payload,
                                  "endTime",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.values.times, function (time) {
                            return _c(
                              "option",
                              {
                                key: time.value,
                                domProps: { value: time.value },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(time.label) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isDateGroupInvalid
                    ? _c("div", { staticClass: "form-error" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.inlineerrors.date) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "more" }, [
                    _c("label", { staticClass: "checkbox checkbox-custom" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.payload.flexibleDate,
                            expression: "payload.flexibleDate",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.payload.flexibleDate)
                            ? _vm._i(_vm.payload.flexibleDate, null) > -1
                            : _vm.payload.flexibleDate,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.payload.flexibleDate,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.payload,
                                    "flexibleDate",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.payload,
                                    "flexibleDate",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.payload, "flexibleDate", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Date flexible")]),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "checkbox checkbox-custom" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.payload.flexibleHours,
                            expression: "payload.flexibleHours",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.payload.flexibleHours)
                            ? _vm._i(_vm.payload.flexibleHours, null) > -1
                            : _vm.payload.flexibleHours,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.payload.flexibleHours,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.payload,
                                    "flexibleHours",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.payload,
                                    "flexibleHours",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.payload, "flexibleHours", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Horaires flexibles")]),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "checkbox checkbox-custom" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.payload.multiDays,
                            expression: "payload.multiDays",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.payload.multiDays)
                            ? _vm._i(_vm.payload.multiDays, null) > -1
                            : _vm.payload.multiDays,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.payload.multiDays,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.payload,
                                    "multiDays",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.payload,
                                    "multiDays",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.payload, "multiDays", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Sur plusieurs jours")]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-row form-row-budget",
                  class: { "has-errors": _vm.isBudgetFieldInvalid },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "required",
                      attrs: { for: "edit-event-budget" },
                    },
                    [_vm._v("Quel est votre budget global ?")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group form-budget" },
                    [
                      _c("icon", { attrs: { icon: "euro-sign-solid" } }),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.payload.budget,
                              expression: "payload.budget",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { required: "" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.payload,
                                "budget",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.values.budgets, function (budget) {
                          return _c(
                            "option",
                            {
                              key: budget.value,
                              domProps: { value: budget.value },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(budget.label) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.isBudgetFieldInvalid
                        ? _c("div", { staticClass: "form-error" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.inlineerrors.budget) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-actions" }, [
        _c(
          "button",
          { staticClass: "ct ct--button cta", attrs: { type: "submit" } },
          [
            _vm._v("\n      Organisation (2/3)\n      "),
            _c("icon", { attrs: { icon: "arrow-right-solid" } }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-header" }, [
      _c("h2", { staticClass: "title" }, [
        _c("span", [_vm._v("Indiquez-nous les modalités")]),
        _vm._v(" de votre événement\n      "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "subtitle" }, [
        _c("span", [_vm._v("Afin de valider la disponibilité et le prix")]),
        _vm._v(" nous avons besoin d’en savoir plus\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }