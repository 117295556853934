import { pushEventToDataLayer } from './data-layer-helper';

const setupDataLayerEventTracking = () => {
  const trackedElements = document.querySelectorAll('[data-tracking]');
  trackedElements.forEach((element) => {
    const eventName = element.getAttribute('data-tracking');
    element.addEventListener('click', () => {
      pushEventToDataLayer(eventName);
    });
  });
};

export default setupDataLayerEventTracking;
