const handleHubspotForms = () => {
  // check if the hubspot form element exists on the page
  const hubspotFormElement = document.body.querySelector('[data-hsform-id]');
  if (!hubspotFormElement) {
    return;
  }

  // create the hubspot form script
  const hubspotFormScript = document.createElement('script');
  hubspotFormScript.src = 'https://js.hsforms.net/forms/v2.js';
  hubspotFormScript.defer = true;
  hubspotFormScript.onload = () => {
    window.hbspt.forms.create({
      portalId: '3906182',
      formId: hubspotFormElement.dataset.hsformId,
      target: hubspotFormElement.dataset.hsformTarget || '.hs-form',
    });
  };

  // append the hubspot form script to the page
  document.body.appendChild(hubspotFormScript);

  // listen for Hubspot form submissions
  window.addEventListener('message', (event) => {
    if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
      window.dataLayer.push({
        event: 'hubspot-form-success',
        'hs-form-guid': event.data.id,
      });
    }
  });
};

export default handleHubspotForms;
