var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "generic-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _vm.loaded === false
        ? _c("div", { staticClass: "form-body-placeholder" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded === true
        ? _c(
            "div",
            { staticClass: "form-body" },
            [
              _c("h3", { staticClass: "title-4" }, [
                _vm._v("\n      Nature de l'événement\n    "),
              ]),
              _vm._v(" "),
              _vm.errors
                ? _c("event-brief-step-form-errors", {
                    attrs: { dismiss: _vm.dismissErrors, errors: _vm.errors },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _vm.fields.guestsNumber === true
                  ? _c("div", { staticClass: "form-col" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-group form-guests",
                          class: {
                            "has-errors": _vm.isGuestsNumberFieldInvalid,
                          },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "required",
                              attrs: { for: "edit-event-guests" },
                            },
                            [_vm._v("Combien de participants attendez vous ?")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.payload.guestsNumber,
                                expression: "payload.guestsNumber",
                              },
                            ],
                            staticClass: "form-control form-control-inline",
                            attrs: {
                              id: "edit-event-guests",
                              type: "number",
                              min: "10",
                              required: "",
                            },
                            domProps: { value: _vm.payload.guestsNumber },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.payload,
                                  "guestsNumber",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v("\n          participants\n        "),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.fields.guestsCategories === true
                  ? _c("div", { staticClass: "form-col" }, [
                      _c(
                        "div",
                        { staticClass: "form-group form-attendees" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "edit-event-attendees" } },
                            [
                              _vm._v(
                                "Qui seront les participants à votre événement ?"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("event-brief-multi-select", {
                            staticClass: "no-placeholder",
                            attrs: {
                              id: "edit-event-attendees",
                              options: _vm.values.guestsCategories,
                              "close-on-select": false,
                              searchable: false,
                              "hide-selected": false,
                              multiple: true,
                              label: "label",
                              "track-by": "value",
                              placeholder: "",
                              "selected-label": "✓",
                              "select-label": "",
                              "deselect-label": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "multiselect__resume" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.joinValues(props.values))
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              751771988
                            ),
                            model: {
                              value: _vm.payload.guestsCategories,
                              callback: function ($$v) {
                                _vm.$set(_vm.payload, "guestsCategories", $$v)
                              },
                              expression: "payload.guestsCategories",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _vm.fields.objectives === true
                  ? _c("div", { staticClass: "form-col" }, [
                      _c(
                        "div",
                        { staticClass: "form-group form-objectives" },
                        [
                          _c(
                            "label",
                            { attrs: { for: "edit-event-objectives" } },
                            [_vm._v("Quel est l'objectif de votre événement ?")]
                          ),
                          _vm._v(" "),
                          _c("event-brief-multi-select", {
                            staticClass: "no-placeholder",
                            attrs: {
                              id: "edit-event-objectives",
                              options: _vm.values.objectives,
                              "close-on-select": false,
                              searchable: false,
                              "hide-selected": false,
                              multiple: true,
                              label: "label",
                              "track-by": "value",
                              placeholder: "",
                              "selected-label": "✓",
                              "select-label": "",
                              "deselect-label": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "multiselect__resume" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.joinValues(props.values))
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              751771988
                            ),
                            model: {
                              value: _vm.payload.objectives,
                              callback: function ($$v) {
                                _vm.$set(_vm.payload, "objectives", $$v)
                              },
                              expression: "payload.objectives",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.fields.services === true
                  ? _c("div", { staticClass: "form-col" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-group form-services",
                          class: { "has-errors": _vm.isServiceFieldInvalid },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "required",
                              attrs: { for: "edit-event-services" },
                            },
                            [
                              _vm._v(
                                "\n            De quel type de prestations avez vous besoin ?\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("event-brief-multi-select", {
                            staticClass: "no-placeholder",
                            attrs: {
                              id: "edit-event-services",
                              options: _vm.values.services,
                              "close-on-select": false,
                              searchable: false,
                              "hide-selected": false,
                              multiple: true,
                              label: "label",
                              "track-by": "value",
                              placeholder: "",
                              "selected-label": "✓",
                              "select-label": "",
                              "deselect-label": "",
                              required: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "multiselect__resume" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.joinValues(props.values))
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "option",
                                  fn: function (props) {
                                    return [
                                      _c("icon", {
                                        attrs: {
                                          icon:
                                            "nav_picto_" +
                                            props.option.reference,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "option__desc" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "option__title" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(props.option.label) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              987085018
                            ),
                            model: {
                              value: _vm.payload.services,
                              callback: function ($$v) {
                                _vm.$set(_vm.payload, "services", $$v)
                              },
                              expression: "payload.services",
                            },
                          }),
                          _vm._v(" "),
                          _vm.isServiceFieldInvalid
                            ? _c("div", { staticClass: "form-error" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.inlineerrors.services) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _vm.fields.schedule === true
                  ? _c("div", { staticClass: "form-group form-program" }, [
                      _c("label", { staticClass: "checkbox checkbox-custom" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.payload.withSchedule,
                              expression: "payload.withSchedule",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.payload.withSchedule)
                              ? _vm._i(_vm.payload.withSchedule, null) > -1
                              : _vm.payload.withSchedule,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.payload.withSchedule,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.payload,
                                      "withSchedule",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.payload,
                                      "withSchedule",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.payload, "withSchedule", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v("Je connais le programme de l'événement"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.payload.schedule,
                            expression: "payload.schedule",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "edit-event-program-desc",
                          placeholder:
                            "Par exemple : Buffet pour le déjeuner, puis projection en conférence dans l'après-midi suivie d'une collation en milieu d'après-midi ...",
                          disabled: !_vm.payload.withSchedule,
                          required: "",
                        },
                        domProps: { value: _vm.payload.schedule },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.payload,
                              "schedule",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.fields.noiseLevel === true
                ? _c("div", { staticClass: "form-row" }, [
                    _c("label", [_vm._v("Volume sonore prévu")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group form-budget" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.payload.noiseLevel,
                              expression: "payload.noiseLevel",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.payload,
                                "noiseLevel",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }),
                          _vm._v(" "),
                          _vm._l(_vm.values.noiseLevels, function (choice) {
                            return _c(
                              "option",
                              {
                                key: choice.value,
                                domProps: { value: choice.value },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(choice.label) +
                                    "\n          "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-actions" }, [
        _c(
          "button",
          {
            staticClass: "ct ct--button cta cta-clear",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.cancel()
              },
            },
          },
          [
            _c("icon", { attrs: { icon: "arrow-left-solid" } }),
            _vm._v("\n      Général (1/3)\n    "),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "ct ct--button cta", attrs: { type: "submit" } },
          [
            _vm._v("\n      Besoins (3/3)\n      "),
            _c("icon", { attrs: { icon: "arrow-right-solid" } }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }