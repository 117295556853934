<template>
  <div
    class="generic-modal-backdrop"
    @click="close()"
  />
</template>

<script>
export default {
  name: 'ModalBackdrop',
  props: {
    close: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null) {
      document.body.classList.add('platform-ios');
      document.body.style.top = -window.scrollY + 'px';
    }
    if (document.body.classList.contains('modal-open') === false) {
      document.body.classList.add('modal-open');
    }
  },
  destroyed() {
    if (document.body.classList.contains('modal-open') === true) {
      document.body.classList.remove('modal-open');
    }
    if (document.body.classList.contains('platform-ios') === true) {
      document.body.classList.remove('platform-ios');
      window.scrollTo(0, -parseInt(document.body.style.top, 10));
      document.body.style.top = '';
    }
  },
};
</script>
