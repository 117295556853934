// https://blog.teamtreehouse.com/learn-asynchronous-image-loading-javascript

const lazyLoadImage = (element) => {
  const src = element.getAttribute('data-bg');
  if (!src) {
    return;
  }
  const downloadingImage = new Image();
  downloadingImage.onload = () => {
    element.removeAttribute('data-bg');
    element.classList.remove('lazyload-slider');
    element.style.backgroundImage = 'url(' + src + ')';
  };
  downloadingImage.src = src;
};

export default lazyLoadImage;
