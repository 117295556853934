<template>
  <div
    v-if="visible"
    class="generic-modal-manager"
    :class="shown_class"
  >
    <modal-backdrop
      v-if="visible_backdrop"
      :close="closeAll"
    />
  </div>
</template>

<script>
import ModalBackdrop from './components/ModalBackdrop.vue';

export default {
  components: {
    ModalBackdrop,
  },
  props: {
    dispatcher: {
      type: Object,
      required: true,
    },
    device: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      shown_class: null,
    };
  },
  computed: {
    visible: function () {
      return false;
    },
    visible_backdrop: function () {
      return false;
    },
  },
  methods: {
    closeAll: function () {
      this.shown_class = null;
    },
    closeDigitalEventModal: function () {
    },
  },
};
</script>
