export const SEARCH_MANAGER_EVENT = {
  ADD_TO: '__sme_add_to',
  CLICK: '__sme_click',
  MOUSE_ENTER: '__sme_mouse_enter',
  MOUSE_LEAVE: '__sme_mouse_leave',
  UPDATE: '__sme_update',
  UPDATE_MAP: '__sme_update_map',
  CLEAR_MAP: '__sme_clear_map',
  REMOVE: '__sme_remove',
  ITEM_MOUSE_OUT: '__sme_item_mouse_out',
  ITEM_MOUSE_ENTER: '__sme_item_mouse_enter',
  MAP_MOVED: '__sme_map_moved',
};
