<template>
  <div
    v-if="visible"
    class="selection-modal-manager"
  >
    <place-added-to-selection-modal
      v-if="place_added"
      :item="place_added"
      :close="closePlaceAddedModal"
      :dispatcher="dispatcher"
    />
    <add-animation-to-selection-modal
      v-if="animation"
      :item="animation"
      :selection-manager="selectionManager"
      :close="closeAnimationModal"
    />
    <add-menu-to-selection-modal
      v-if="menu"
      :item="menu"
      :selection-manager="selectionManager"
      :close="closeMenuModal"
    />
    <add-drink-to-selection-modal
      v-if="drink"
      :item="drink"
      :selection-manager="selectionManager"
      :close="closeDrinkModal"
    />
    <add-place-to-selection-modal
      v-if="place"
      :item="place"
      :selection-manager="selectionManager"
      :close="closePlaceModal"
    />
    <confirm-clear-selection-modal
      v-if="confirm"
      :close="closeConfirmModal"
      :selection-manager="selectionManager"
    />
    <authentication-required-modal
      v-if="auth"
      :close="closeAuthModal"
    />
    <only-places-selected-modal
      v-if="places_only"
      :close="closePlacesOnlyModal"
    />
    <edit-event-brief-modal
      v-if="event_brief"
      :close="closeEventBriefModal"
      :selection-manager="selectionManager"
      :device="device"
    />
    <launch-selection-tour-modal
      v-if="selection_tour"
      :close="closeSelectionTourModal"
      :dispatcher="dispatcher"
    />
    <merge-selections-modal
      v-if="merge_selections"
      :close="closeMergeSelections"
      :selection-manager="selectionManager"
      :selection="previous_selection"
    />
    <modal-backdrop :close="closeAll" />
  </div>
</template>

<script>
import { AUTHENTICATION_REQUIRED } from '../../../events/auth-events';
import { SELECTION_ITEM_ADDED } from '../../../events/selection-events';
import {
  CONFIGURE_ITEM_FOR_SELECTION,
  CONFIRM_CLEAR_SELECTION,
  SHOW_PLACES_ONLY_MODAL,
  SHOW_MERGE_SELECTIONS_MODAL,
  SHOW_REQUEST_QUOTATION_MODAL,
  SHOW_SELECTION_TOUR_MODAL,
} from '../../../messages/selection-messages';
import AddAnimationToSelectionModal from './components/AddAnimationToSelectionModal.vue';
import AddDrinkToSelectionModal from './components/AddDrinkToSelectionModal.vue';
import AddMenuToSelectionModal from './components/AddMenuToSelectionModal.vue';
import AddPlaceToSelectionModal from './components/AddPlaceToSelectionModal.vue';
import AuthenticationRequiredModal from './components/AuthenticationRequiredModal.vue';
import ConfirmClearSelectionModal from './components/ConfirmClearSelectionModal.vue';
import EditEventBriefModal from './components/EditEventBriefModal.vue';
import LaunchSelectionTourModal from './components/LaunchSelectionTourModal.vue';
import MergeSelectionsModal from './components/MergeSelectionsModal.vue';
import ModalBackdrop from './components/ModalBackdrop.vue';
import OnlyPlacesSelectedModal from './components/OnlyPlacesSelectedModal.vue';
import PlaceAddedToSelectionModal from './components/PlaceAddedToSelectionModal.vue';

export default {
  components: {
    ModalBackdrop,
    AddAnimationToSelectionModal,
    AddDrinkToSelectionModal,
    AddMenuToSelectionModal,
    AddPlaceToSelectionModal,
    ConfirmClearSelectionModal,
    PlaceAddedToSelectionModal,
    AuthenticationRequiredModal,
    OnlyPlacesSelectedModal,
    EditEventBriefModal,
    LaunchSelectionTourModal,
    MergeSelectionsModal,
  },
  props: {
    dispatcher: {
      type: Object,
      required: true,
    },
    selectionManager: {
      type: Object,
      required: true,
    },
    device: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      place: null,
      place_added: null,
      animation: null,
      menu: null,
      drink: null,
      auth: null,
      confirm: null,
      places_only: null,
      event_brief: null,
      selection_tour: null,
      // merge selection modal
      merge_selections: null,
      previous_selection: null,
    };
  },
  computed: {
    visible: function () {
      return this.place || this.animation || this.menu || this.drink || this.auth || this.confirm
            || this.places_only || this.event_brief || this.selection_tour || this.place_added
            || this.merge_selections || this.previous_selection;
    },
  },
  mounted() {
    this.dispatcher.on(AUTHENTICATION_REQUIRED, () => { // show authentication required modal
      this.auth = true;
    });
    this.dispatcher.on(SHOW_SELECTION_TOUR_MODAL, () => { // show authentication required modal
      this.selection_tour = true;
    });
    this.dispatcher.on(SHOW_PLACES_ONLY_MODAL, () => { // show places only selected modal
      this.places_only = true;
    });
    this.dispatcher.on(SHOW_REQUEST_QUOTATION_MODAL, () => { // show request quotation confirmation modal
      this.event_brief = true;
    });
    this.dispatcher.on(CONFIRM_CLEAR_SELECTION, () => { // show confirm clear selection modal
      this.confirm = true;
    });
    this.dispatcher.on(CONFIGURE_ITEM_FOR_SELECTION, (item) => { // show product configuration modal
      if (item.type === 'option') { // show global "configure options" modal
        this.animation = item;
      } else if (item.type === 'drink') { // show "configure drink" modal
        this.drink = item;
      } else if (item.type === 'menu') { // show "configure menu" modal
        this.menu = item;
      } else if (item.type === 'place') { // show "configure period" modal
        this.place = item;
      }
    });
    this.dispatcher.on(SELECTION_ITEM_ADDED, (item) => { // show place added modal
      if (item.item_type !== 'place' || item.is_first === false) {
        return;
      }
      this.place_added = item;
    });
    this.dispatcher.on(SHOW_MERGE_SELECTIONS_MODAL, (selection) => {
      this.previous_selection = selection;
      this.merge_selections = true;
    });
  },
  methods: {
    closeAll: function () {
      if (this.event_brief) {
        return;
      }
      this.place = null;
      this.place_added = null;
      this.animation = null;
      this.menu = null;
      this.drink = null;
      this.auth = null;
      this.confirm = null;
      this.places_only = null;
      this.event_brief = null;
      this.selection_tour = null;
      this.merge_selections = null;
      this.previous_selection = null;
    },
    closePlaceModal: function () {
      this.place = null;
    },
    closePlaceAddedModal: function () {
      this.place_added = null;
    },
    closeMenuModal: function () {
      this.menu = null;
    },
    closeDrinkModal: function () {
      this.drink = null;
    },
    closeAnimationModal: function () {
      this.animation = null;
    },
    closeAuthModal: function () {
      this.auth = null;
    },
    closeConfirmModal: function () {
      this.confirm = null;
    },
    closePlacesOnlyModal: function () {
      this.places_only = null;
    },
    closeEventBriefModal: function () {
      this.event_brief = null;
    },
    closeSelectionTourModal: function () {
      this.selection_tour = null;
    },
    closeMergeSelections: function () {
      this.merge_selections = null;
      this.previous_selection = null;
    },
  },
};
</script>
