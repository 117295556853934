var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selection-modal place-added-modal" }, [
    _c("div", { staticClass: "selection-modal-content" }, [
      _c(
        "button",
        {
          staticClass: "close-button",
          attrs: { title: "Fermer" },
          on: {
            click: function ($event) {
              return _vm.close()
            },
          },
        },
        [_c("icon", { attrs: { icon: "panier_picto_suppr" } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "visual" }, [
        _c("div", { staticClass: "img-wrapper" }, [
          _c("img", {
            attrs: { src: _vm.item.item_image.url, alt: "Aperçu du lieu" },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "functional" }, [
        _c("div", { staticClass: "content" }, [
          _c("h2", { staticClass: "title-2" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.item.item_title) +
                " a bien été ajouté à votre sélection\n        "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-1" }, [
            _vm._v(
              "\n          Vous pouvez continuer de parcourir notre catalogue et ajouter d'autres lieux et prestations à votre\n          sélection\n          pour votre événement. Ou vous pouvez demander la disponibilité de ce lieu au propriétaire et\n          commencer tout de suite l'organisation de votre événement.\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "ct ct--button cta cta-clear",
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v("\n          Poursuivre ma sélection\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "ct ct--button cta",
              on: { click: _vm.checkSelection },
            },
            [_vm._v("\n          J'ai terminé ma sélection\n        ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }