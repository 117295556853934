import { EventEmitter } from 'eventemitter3';
import { scrollToYOffset } from './scroll-to-helper';
import { SEARCH_MANAGER_EVENT } from './searchManagerEvent';
import GenericSlider from '../components/generic-slider/generic-slider';

export default class SearchManager extends EventEmitter {
  constructor(page) {
    super();

    // this.Basket = null;
    this.ItemResult = {};
    this.dom = {};
    this.dom.page = page;
    this.dom.resultsWrapper = this.dom.page.querySelector('.all-results');
    this.dom.sortingBar = this.dom.page.querySelector('.sorts-bar');

    this._scrollDuration = 0.5;

    this._isSearchPlace = false;

    this._binds = {};
  }

  _retrieveDom() {
    this.dom.itemsResults = this.dom.page.querySelectorAll('.ct--item-result');
  }

  _initItemsResults(isFirst = true) {
    const mapArray = [];
    for (let i = 0; i < this.dom.itemsResults.length; i++) {
      const _el = this.dom.itemsResults[i];
      const _id = _el.getAttribute('data-id');
      const _image = _el.getAttribute('data-image');
      const _type = _el.getAttribute('data-type');
      if (_type === 'place') {
        const _lat = _el.getAttribute('data-lat');
        const _lng = _el.getAttribute('data-lng');

        this._isSearchPlace = true;

        const elMap = {
          id: _id,
          image: _image,
          coordinates: [_lng, _lat],
        };

        mapArray.push(elMap);
      }

      this.ItemResult[_id] = new ItemResult(_el, !isFirst);

      if (this._isSearchPlace) {
        _el.addEventListener('mouseenter', () => {
          this.emit(SEARCH_MANAGER_EVENT.ITEM_MOUSE_ENTER, _id);
        });
        _el.addEventListener('mouseleave', () => {
          this.emit(SEARCH_MANAGER_EVENT.ITEM_MOUSE_OUT, _id);
        });
      }
    }

    if (this._isSearchPlace) {
      this.emit(SEARCH_MANAGER_EVENT.UPDATE_MAP, mapArray);
    }
  }

  _unsetItemsResults() {
    if (this._isSearchPlace == true) {
      this.emit(SEARCH_MANAGER_EVENT.CLEAR_MAP);
      this._isSearchPlace = false;
    }
    for (const key in this.ItemResult) {
      if (this.ItemResult.hasOwnProperty(key)) {
        delete this.ItemResult.key;
      }
    }
  }

  scrollToItem(id) {
    const itemTop = this.ItemResult[id].getOffsetTop();
    scrollToYOffset(itemTop);
  }

  scrollToTop() {
    let top = this.dom.resultsWrapper.offsetTop;
    if (this.dom.sortingBar) {
      top = this.dom.sortingBar.offsetTop - 16;
    }
    scrollToYOffset(top);
  }

  setItemResultHover(id) {
    this.ItemResult[id].setHover();
  }

  unsetItemResultHover(id) {
    this.ItemResult[id].unsetHover();
  }

  _onItemResult() {
    // EVENT :: ADD TO when Click on Item Result BTN
    for (const key in this.ItemResult) {
      if (this.ItemResult.hasOwnProperty(key)) {
        this.ItemResult[key].on(SEARCH_MANAGER_EVENT.ADD_TO, (objControls) => {
          this.emit(SEARCH_MANAGER_EVENT.ADD_TO, objControls);
        });
      }
    }
  }

  _offItemResult() {
    // EVENT :: ADD TO when Click on Item Result BTN
    for (const key in this.ItemResult) {
      if (this.ItemResult.hasOwnProperty(key)) {
        this.ItemResult[key].off(SEARCH_MANAGER_EVENT.ADD_TO);
      }
    }
  }

  _process() {
    this.emit(SEARCH_MANAGER_EVENT.UPDATE);
  }

  rewriteHTML() {
    this.dom.resultsWrapper.innerHTML = '';
  }

  bind(page) {
    this._retrieveDom(page);
    this._initItemsResults();
    this._onItemResult();
  }

  unbind() {
    this._unsetItemsResults();
    this._offItemResult();
  }

  rebind(page) {
    this._retrieveDom(page);
    this._initItemsResults(false);
    this._onItemResult();
  }
}

class ItemResult extends EventEmitter {
  constructor(component, initializeSlider = false) {
    super();
    this._Controls = [];
    this._initDom(component);
    this._initControls();
    this._initEvents();
    // components are not initialized after first page load
    // so we initialize it manually on ajax request success
    if (initializeSlider === true) {
      this._initSlider();
    }
    this._id = component.getAttribute('data-id');
    this._type = component.getAttribute('data-type');
  }

  _initDom(component) {
    this.dom = {};
    this.dom.component = component;
    this.dom.controls = this.dom.component.querySelectorAll(
      '.ct--control-quantity',
    );
    this.dom.option = this.dom.component.querySelector('.js-choose-option');
    this.dom.btn = this.dom.component.querySelector('.js-btn-add-basket');
    this.dom.slider = this.dom.component.querySelector('[data-component="generic-slider"]');
  }

  _initSlider() {
    if (this.dom.slider === null) {
      return;
    }
    const slider = new GenericSlider(this.dom.slider);
    slider.init();
    slider.bind();
  }

  _initEvents() {
    if (this.dom.btn) {
      this.dom.btn.addEventListener('click', this._add.bind(this));
    }
  }

  _initControls() {
    for (let i = 0; i < this.dom.controls.length; i++) {
      const control = this.dom.controls[i];
      this._Controls.push(new ControlQuantity(control));
    }
  }

  // ADD BASKET
  _add() {
    const objControls = {};

    objControls['id'] = this._id;
    objControls['type'] = this._type;

    for (let i = 0, j = this._Controls.length; i < j; i++) {
      const obj = this._Controls[i].getObject();
      objControls[obj.name] = obj.values;
    }

    if (this.dom.option) {
      const value = this.dom.option.querySelector('select').value;
      objControls['option'] = value;
    }

    this.emit(SEARCH_MANAGER_EVENT.ADD_TO, objControls);
    this.addSelectedClass();
  }

  addSelectedClass() {
    this.dom.component.classList.add('selected');
  }

  removeSelectedClass() {
    this.dom.component.classList.remove('selected');
  }

  getOffsetTop() {
    return this.dom.component.offsetTop;
  }

  setHover() {
    this.dom.component.classList.add('hover-map');
  }

  unsetHover() {
    this.dom.component.classList.remove('hover-map');
  }
}

class ControlQuantity {
  constructor(element) {
    this._params = {
      min: +element.getAttribute('data-min'),
      max: +element.getAttribute('data-max'),
      step: +element.getAttribute('data-step'),
      name: element.getAttribute('data-name'),
    };

    this._value = +element.getAttribute('data-min');

    this._initDom(element);
    this._events();
  }

  _initDom(element) {
    this._dom = {};
    this._dom.more = element.querySelector('.js-more-quantity');
    this._dom.less = element.querySelector('.js-less-quantity');
    this._dom.response = element.querySelector('.js-response-value');
  }

  _events() {
    this._dom.more.addEventListener('click', this._process.bind(this, 'more'));
    this._dom.less.addEventListener('click', this._process.bind(this, 'less'));
    this._dom.response.addEventListener('blur', this._checkInput.bind(this));
    this._dom.response.addEventListener('change', this._checkInput.bind(this));
  }

  _process(where) {
    if (where == 'more' && this._value < this._params.max) {
      this._value = this._value + this._params.step;
    } else if (where == 'less' && this._value > this._params.min) {
      this._value = this._value - this._params.step;
    }

    this._dom.response.value = this._value;
  }

  _checkInput() {
    if (this._dom.response.value > this._params.max) {
      this._dom.response.value = this._params.max;
      this._value = this._params.max;
    } else if (this._dom.response.value < this._params.min) {
      this._dom.response.value = this._params.min;
      this._value = this._params.min;
    } else {
      this._value = parseInt(this._dom.response.value);
    }
  }

  getObject() {
    return {
      name: this._params.name,
      values: this._value,
    };
  }
}
