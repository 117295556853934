import {
  SEARCH_3D_VISIT_OPENED,
  SEARCH_FILTER_CHANGED,
  SEARCH_FILTERS_OPENED,
  SEARCH_FILTERS_SUBMITTED,
  SEARCH_FILTERS_CLEARED,
  SEARCH_MAP_BOUNDS_CHANGED,
  SEARCH_MAP_MARKER_CLICKED,
  SHOW_3D_VISIT_OPENED,
} from '../events/data-layer-events';

export const buildTrackingEvent = (name, payload = {}) => {
  switch (name) {
    case SEARCH_FILTERS_OPENED:
      return {
        category: 'Filtres de recherche',
        action: 'Ouverture',
        label: payload.name === 'popin-all-filters' ? 'Tous les filtres' : 'Autres filtres',
      };
    case SEARCH_FILTERS_SUBMITTED:
      return {
        category: 'Filtres de recherche',
        action: 'Soumission',
        label: payload.name || '',
      };
    case SEARCH_FILTERS_CLEARED:
      return {
        category: 'Filtres de recherche',
        action: 'Réinitialisation',
        label: payload.name || '',
      };
    case SEARCH_FILTER_CHANGED:
      if (typeof payload.name === 'undefined' || payload.name === 'page' || payload.name === 'bounds') {
        return;
      }
      return {
        category: 'Filtres de recherche',
        action: 'Changement',
        label: payload.label || payload.name || '',
      };
    case SEARCH_MAP_BOUNDS_CHANGED:
      return {
        category: 'Carte de résultats',
        action: 'Intéraction',
        label: payload.type === 'zoomend' ? 'Zoom in/out' : 'Déplacement',
      };
    case SEARCH_MAP_MARKER_CLICKED:
      return {
        category: 'Carte de résultats',
        action: 'Intéraction',
        label: 'Clic marqueur',
      };
    case SEARCH_3D_VISIT_OPENED:
      return {
        category: 'Visite 3D',
        action: 'Ouverture',
        label: 'Page de recherche',
      };
    case SHOW_3D_VISIT_OPENED:
      return {
        category: 'Visite 3D',
        action: 'Ouverture',
        label: 'Page de détail',
      };
  }
};
