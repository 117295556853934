<template>
  <svg
    :class="`icon svg-${icon}`"
    role="img"
  >
    <use :xlink:href="`${path}#svg-${icon}`" />
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      path: window.App.IconsPath || '/build/spritemap.svg',
    };
  },
};
</script>
