import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import Component from '../component';

export default class LogosSlider extends Component {
  bind() {
    new Splide(this.dom.component, {
      type: 'loop',
      drag: 'free',
      gap: 50,
      pagination: false,
      arrows: false,
      autoWidth: true,
      autoScroll: {
        speed: 0.5,
      },
    }).mount({ AutoScroll });
  }
}
