var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selection-modal confirm-clear-selection-modal" },
    [
      _c("div", { staticClass: "selection-modal-content" }, [
        _c(
          "button",
          {
            staticClass: "close-button",
            attrs: { title: "Fermer" },
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_c("icon", { attrs: { icon: "panier_picto_suppr" } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "full" }, [
          _c("div", { staticClass: "content" }, [
            _c("h2", { staticClass: "title-2" }, [
              _vm._v(
                "\n          Attention ! Vous êtes sur le point de vider votre sélection\n        "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-1" }, [
              _vm._v(
                "\n          Votre sélection de lieux et de prestations sera totalement vide après cette action.\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "ct ct--button cta cta-clear",
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              },
              [_vm._v("\n          Retourner à ma navigation\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "ct ct--button cta",
                on: {
                  click: function ($event) {
                    return _vm.clear()
                  },
                },
              },
              [
                _c("icon", { attrs: { icon: "panier_trash" } }),
                _vm._v("\n          Vider ma sélection\n        "),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }