<template>
  <div
    v-if="animation"
    class="selection-modal add-menu-modal"
  >
    <div class="selection-modal-content">
      <button
        class="close-button"
        title="Fermer"
        @click="close()"
      >
        <icon icon="panier_picto_suppr" />
      </button>
      <!-- IMAGE -->
      <div class="visual">
        <div class="img-wrapper">
          <img
            :src="animation.image.url"
            alt="Aperçu de la prestation"
          >
        </div>
        <a
          v-if="animation.uri && item.context === 'list'"
          :href="animation.uri"
          class="ct ct--button cta cta-infos"
          title="Voir les détails"
        >
          Voir les détails
        </a>
      </div>
      <!-- TEXT -->
      <div class="functional">
        <div class="content">
          <h2 class="title-2">
            Ajouter "{{ animation.title }}" à votre sélection ?
          </h2>
          <form @submit.prevent="submit">
            <!-- PACKS -->
            <div
              v-if="animation.add_options.pack"
              class="radio-wrapper"
            >
              <p class="text-1">
                Sélectionnez la formule désirée :
              </p>
              <ul>
                <li
                  v-for="choice in animation.add_options.pack.choices"
                  :key="choice.value"
                >
                  <label>
                    <input
                      v-model="payload.pack"
                      type="radio"
                      :value="choice.value"
                    >
                    <strong>{{ choice.price }}</strong>
                    {{ ' ' }}
                    {{ choice.label }}
                  </label>
                </li>
              </ul>
            </div>
            <div v-if="animation.add_options.quantity && animation.add_options.duration">
              <p class="text-1">
                Sélectionnez le nombre et la durée désirés :
              </p>
              <div class="inputs-line">
                <div class="input-wrapper">
                  <label>Nombre</label>
                  <input
                    v-model="payload.quantity"
                    type="number"
                    class="quantity"
                    :min="animation.add_options.quantity.min"
                    :max="animation.add_options.quantity.max"
                    required
                  >
                </div>
                <div class="input-wrapper">
                  <label>Pendant</label>
                  <input
                    v-model="payload.duration"
                    type="number"
                    class="duration"
                    :min="animation.add_options.duration.min"
                    :max="animation.add_options.duration.max"
                    required
                  >
                  <span class="input-suffix">h</span>
                </div>
              </div>
            </div>
            <!-- QUANTITY -->
            <div
              v-else-if="animation.add_options.quantity"
              class="slider-wrapper"
            >
              <p class="text-1">
                Sélectionnez la quantité désirée :
                <br>
                <span class="title-2">x</span>
                <input
                  v-model="payload.quantity"
                  class="quantity-input"
                  type="number"
                  :min="animation.add_options.quantity.min"
                  :max="animation.add_options.quantity.max"
                  @change="quantityInputUpdate"
                >
              </p>
              <slider
                ref="quantitySlider"
                :config="slider.quantity"
                :values="[payload.quantity]"
                @change="quantitySliderUpdate"
              />
            </div>
            <!-- DURATION -->
            <div
              v-else-if="animation.add_options.duration"
              class="slider-wrapper"
            >
              <p class="text-1">
                Sélectionnez la durée désirée :
                <br>
                <input
                  v-model="payload.duration"
                  type="number"
                  class="quantity-input"
                  :min="animation.add_options.duration.min"
                  :max="animation.add_options.duration.max"
                  @change="durationInputUpdate"
                >
                <span
                  v-if="payload.duration === 1"
                  class="title-2"
                >heure</span>
                <span
                  v-if="payload.duration > 1"
                  class="title-2"
                >heures</span>
              </p>
              <slider
                ref="durationSlider"
                :config="slider.duration"
                :values="[payload.duration]"
                @change="durationSliderUpdate"
              />
            </div>
            <!-- ADDITIONAL COSTS INFO -->
            <div v-if="animation.version_type === 'equipment'">
              <p class="text-1 additional-cost-mention">
                Des frais de livraison, de montage et démontage, peuvent s'appliquer après la sélection du lieu définitif de l'événement, (compter en moyenne 150 € HT).
              </p>
            </div>
            <div class="form-actions">
              <!--  SUBMIT -->
              <button
                class="ct ct--button cta cta-clear"
                @click="close()"
              >
                Annuler
              </button>
              <button
                type="submit"
                class="ct ct--button cta"
              >
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../../helpers/product-api';
import Icon from '../../../icon/Icon.vue';
import Slider from './Slider.vue';

export default {
  name: 'AddAnimationToSelectionModal',
  components: { Slider, Icon },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    close: {
      type: Function,
      required: true,
    },
    selectionManager: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      animation: null,
      payload: null,
      slider: {
        quantity: {
          step: 1,
          range: {
            min: 1,
            max: 10,
          },
        },
        duration: {
          step: 1,
          range: {
            min: 1,
            max: 10,
          },
        },
      },
    };
  },
  mounted: function () {
    api.getOption(this.item.id).then((response) => {
      const options = response.data.data.add_options;
      const payload = {};
      if (options['pack']) {
        payload.pack = 0;
      }
      if (options['quantity']) {
        payload.quantity = options['quantity']['min'];
        this.slider.quantity = {
          step: options['quantity']['step'],
          range: {
            min: options['quantity']['min'],
            max: options['quantity']['max'],
          },
        };
      }
      if (options['duration']) {
        payload.duration = options['duration']['min'];
        this.slider.duration = {
          step: options['duration']['step'],
          range: {
            min: options['duration']['min'],
            max: options['duration']['max'],
          },
        };
      }
      this.payload = payload;
      this.animation = response.data.data;
    });
  },
  methods: {
    submit: function () {
      const payload = { id: this.item.id, type: this.item.type, data: this.payload };
      this.selectionManager.addItemToSelection(payload).catch((error) => {
        if (error.response && error.response.status === 401) {
          return;
        }
        throw error; // if 400 => handle errors
      }).finally(() => {
        this.close();
      });
    },
    durationSliderUpdate: function (value) {
      this.payload.duration = parseInt(value[0]);
    },
    quantitySliderUpdate: function (value) {
      this.payload.quantity = parseInt(value[0]);
    },
    quantityInputUpdate: function (changeEvent) {
      this.$refs.quantitySlider.updateSlider(parseInt(changeEvent.target.value));
    },
    durationInputUpdate: function (changeEvent) {
      this.$refs.durationSlider.updateSlider(parseInt(changeEvent.target.value));
    },
  },
};
</script>
