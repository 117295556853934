var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.animation
    ? _c("div", { staticClass: "selection-modal add-menu-modal" }, [
        _c("div", { staticClass: "selection-modal-content" }, [
          _c(
            "button",
            {
              staticClass: "close-button",
              attrs: { title: "Fermer" },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_c("icon", { attrs: { icon: "panier_picto_suppr" } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "visual" }, [
            _c("div", { staticClass: "img-wrapper" }, [
              _c("img", {
                attrs: {
                  src: _vm.animation.image.url,
                  alt: "Aperçu de la prestation",
                },
              }),
            ]),
            _vm._v(" "),
            _vm.animation.uri && _vm.item.context === "list"
              ? _c(
                  "a",
                  {
                    staticClass: "ct ct--button cta cta-infos",
                    attrs: {
                      href: _vm.animation.uri,
                      title: "Voir les détails",
                    },
                  },
                  [_vm._v("\n        Voir les détails\n      ")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "functional" }, [
            _c("div", { staticClass: "content" }, [
              _c("h2", { staticClass: "title-2" }, [
                _vm._v(
                  '\n          Ajouter "' +
                    _vm._s(_vm.animation.title) +
                    '" à votre sélection ?\n        '
                ),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.animation.add_options.pack
                    ? _c("div", { staticClass: "radio-wrapper" }, [
                        _c("p", { staticClass: "text-1" }, [
                          _vm._v(
                            "\n              Sélectionnez la formule désirée :\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          _vm._l(
                            _vm.animation.add_options.pack.choices,
                            function (choice) {
                              return _c("li", { key: choice.value }, [
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.payload.pack,
                                        expression: "payload.pack",
                                      },
                                    ],
                                    attrs: { type: "radio" },
                                    domProps: {
                                      value: choice.value,
                                      checked: _vm._q(
                                        _vm.payload.pack,
                                        choice.value
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.payload,
                                          "pack",
                                          choice.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("strong", [_vm._v(_vm._s(choice.price))]),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(" ") +
                                      "\n                  " +
                                      _vm._s(choice.label) +
                                      "\n                "
                                  ),
                                ]),
                              ])
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.animation.add_options.quantity &&
                  _vm.animation.add_options.duration
                    ? _c("div", [
                        _c("p", { staticClass: "text-1" }, [
                          _vm._v(
                            "\n              Sélectionnez le nombre et la durée désirés :\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "inputs-line" }, [
                          _c("div", { staticClass: "input-wrapper" }, [
                            _c("label", [_vm._v("Nombre")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.payload.quantity,
                                  expression: "payload.quantity",
                                },
                              ],
                              staticClass: "quantity",
                              attrs: {
                                type: "number",
                                min: _vm.animation.add_options.quantity.min,
                                max: _vm.animation.add_options.quantity.max,
                                required: "",
                              },
                              domProps: { value: _vm.payload.quantity },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.payload,
                                    "quantity",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-wrapper" }, [
                            _c("label", [_vm._v("Pendant")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.payload.duration,
                                  expression: "payload.duration",
                                },
                              ],
                              staticClass: "duration",
                              attrs: {
                                type: "number",
                                min: _vm.animation.add_options.duration.min,
                                max: _vm.animation.add_options.duration.max,
                                required: "",
                              },
                              domProps: { value: _vm.payload.duration },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.payload,
                                    "duration",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "input-suffix" }, [
                              _vm._v("h"),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm.animation.add_options.quantity
                    ? _c(
                        "div",
                        { staticClass: "slider-wrapper" },
                        [
                          _c("p", { staticClass: "text-1" }, [
                            _vm._v(
                              "\n              Sélectionnez la quantité désirée :\n              "
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticClass: "title-2" }, [
                              _vm._v("x"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.payload.quantity,
                                  expression: "payload.quantity",
                                },
                              ],
                              staticClass: "quantity-input",
                              attrs: {
                                type: "number",
                                min: _vm.animation.add_options.quantity.min,
                                max: _vm.animation.add_options.quantity.max,
                              },
                              domProps: { value: _vm.payload.quantity },
                              on: {
                                change: _vm.quantityInputUpdate,
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.payload,
                                    "quantity",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("slider", {
                            ref: "quantitySlider",
                            attrs: {
                              config: _vm.slider.quantity,
                              values: [_vm.payload.quantity],
                            },
                            on: { change: _vm.quantitySliderUpdate },
                          }),
                        ],
                        1
                      )
                    : _vm.animation.add_options.duration
                    ? _c(
                        "div",
                        { staticClass: "slider-wrapper" },
                        [
                          _c("p", { staticClass: "text-1" }, [
                            _vm._v(
                              "\n              Sélectionnez la durée désirée :\n              "
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.payload.duration,
                                  expression: "payload.duration",
                                },
                              ],
                              staticClass: "quantity-input",
                              attrs: {
                                type: "number",
                                min: _vm.animation.add_options.duration.min,
                                max: _vm.animation.add_options.duration.max,
                              },
                              domProps: { value: _vm.payload.duration },
                              on: {
                                change: _vm.durationInputUpdate,
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.payload,
                                    "duration",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.payload.duration === 1
                              ? _c("span", { staticClass: "title-2" }, [
                                  _vm._v("heure"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.payload.duration > 1
                              ? _c("span", { staticClass: "title-2" }, [
                                  _vm._v("heures"),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("slider", {
                            ref: "durationSlider",
                            attrs: {
                              config: _vm.slider.duration,
                              values: [_vm.payload.duration],
                            },
                            on: { change: _vm.durationSliderUpdate },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.animation.version_type === "equipment"
                    ? _c("div", [
                        _c(
                          "p",
                          { staticClass: "text-1 additional-cost-mention" },
                          [
                            _vm._v(
                              "\n              Des frais de livraison, de montage et démontage, peuvent s'appliquer après la sélection du lieu définitif de l'événement, (compter en moyenne 150 € HT).\n            "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "ct ct--button cta cta-clear",
                        on: {
                          click: function ($event) {
                            return _vm.close()
                          },
                        },
                      },
                      [_vm._v("\n              Annuler\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "ct ct--button cta",
                        attrs: { type: "submit" },
                      },
                      [_vm._v("\n              Ajouter\n            ")]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }