import axios from 'axios';
import { pushToDataLayer } from './data-layer-helper';

const setUpDataLayerInterceptors = () => {
  // add a request interceptor
  axios.interceptors.request.use((config) => {
    config.headers.post['x-requested-with'] = 'XMLHttpRequest';
    config.headers.post['x-with-data-layer'] = '1';
    return config;
  }, (error) => Promise.reject(error));
  // add a response interceptor
  axios.interceptors.response.use((response) => {
    if (typeof response.headers['x-data-layer'] === 'string') {
      try {
        const data = JSON.parse(response.headers['x-data-layer']);
        pushToDataLayer(data);
      } catch (error) {
        console.error(error);
      }
    }
    return response;
  }, (error) => Promise.reject(error));
};

export default setUpDataLayerInterceptors;
