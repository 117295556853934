import { gsap } from 'gsap';
import lazyLoadImage from '../../lib/image/image-loader';
import Swipe from '../../helpers/swipe';
import loopIndex from '../../lib/math/loop-index';
import Component from '../component';

export default class GenericSlider extends Component {
  constructor(...args) {
    super(...args);
    this._current = 0;
    this._next = 0;
    this._duration = 0.45;
    this._running = false;
    this._initDom();
    this._initSlider();
  }

  _initDom() {
    this.dom.slides = this.dom.component.querySelectorAll('.js-slider-slide');
    this.dom.arrows = this.dom.component.querySelectorAll('.js-slider-arrow');
    this.dom.bullets = this.dom.component.querySelectorAll('.js-slider-bullet');
  }

  _initSlider() {
    if (this.dom.slides.length === 0) {
      return;
    }
    new Swipe(this.dom.component, this._swipe.bind(this));
  }

  bind() {
    super.bind();
    // set event name
    const clickEvent = navigator.userAgent.match(/iPad/i) ? 'touchstart' : 'click';
    // bind arrows handler
    for (let i = 0; i < this.dom.arrows.length; i++) {
      const el = this.dom.arrows[i];
      el.addEventListener(clickEvent, (e) => {
        e.preventDefault();
        e.stopPropagation();
        this._clickArrow(el);
      });
    }
    // bind bullets handler
    for (let i = 0; i < this.dom.bullets.length; i++) {
      const el = this.dom.bullets[i];
      el.addEventListener(clickEvent, (e) => {
        e.preventDefault();
        e.stopPropagation();
        this._clickBullet(i);
      });
    }
  }

  _swipe(way) {
    if (this._running) {
      return;
    }
    const operator = way === 'left' ? -1 : 1;
    this._handleNextSlide(operator);
  }

  _clickBullet(index) {
    if (this._running) {
      return;
    }
    this._next = index;
    lazyLoadImage(this.dom.slides[this._next]);
    this._process();
  }

  _clickArrow(el) {
    if (this._running) {
      return;
    }
    const operator = el.classList.contains('left') ? -1 : 1;
    this._handleNextSlide(operator);
  }

  _handleNextSlide(operator) {
    this._next = loopIndex(this._current + operator, this.dom.slides.length);
    this._nextNext = loopIndex(this._next + operator, this.dom.slides.length);
    lazyLoadImage(this.dom.slides[this._next]);
    lazyLoadImage(this.dom.slides[this._nextNext]);
    this._process();
  }

  _process() {
    if (this._running) {
      return;
    }
    this._bullets();
    this._anim();
  }

  _bullets() {
    if (this.dom.bullets.length > 0) {
      for (let i = 0; i < this.dom.bullets.length; i++) {
        this.dom.bullets[i].classList.remove('active');
      }
      this.dom.bullets[this._next].classList.add('active');
    }
  }

  _anim() {
    this._running = true;
    gsap.to(this.dom.slides[this._current], {
      autoAlpha: 0,
      duration: this._duration,
      ease: 'power3.in',
      'z-index': 1,
    });
    gsap.to(this.dom.slides[this._next], {
      autoAlpha: 1,
      duration: this._duration * 0.75,
      ease: 'power3.in',
      'z-index': 2,
      onComplete: () => {
        this._current = this._next;
        this._running = false;
      },
    });
  }
}
