// import $ from 'jquery'

// export const scrollToOffset = (offset, duration = 1) => {
//   $('html, body').stop().animate({scrollTop: offset}, duration * 500);
// };

export const scrollToYOffset = (offset) => {
  window.scrollTo({ top: offset, behavior: 'smooth' });
};

export const scrollToXOffset = (offset) => {
  window.scrollTo({ left: offset, behavior: 'smooth' });
};
