import icon from './icon';

export default class NotificationCenterManager {
  constructor() {
    this._Notifications = [];
    this._notifications = null;
    this._dom = {};
    this._getDom();
    this._init();
  }

  _getDom() {
    this._dom.center = document.querySelector('.js-notification-center');
    this._dom.toaster = document.querySelector('.js-toaster-manager');
  }

  _init() { // handle notifications found in the DOM
    if (typeof NOTIFICATIONS !== 'undefined') {
      this._notifications = NOTIFICATIONS;
      for (let i = 0; i < this._notifications.length; i++) {
        this.notify(this._notifications[i]);
      }
    }
  }

  notify(opts) {
    if (opts.hasOwnProperty('title')) {
      this._Notifications.push(new Toast(this._dom.toaster, opts));
    } else {
      this._Notifications.push(new Flash(this._dom.center, opts));
    }
  }
}

class Notification {
  constructor(container, opts) {
    this._duration = 3000;
    this._delay = 0;
    this._cssDelay = 500;
    this._dom = {};
    this._dom.container = container;

    if (opts.hasOwnProperty('delay')) {
      this._delay = opts.delay;
    }

    if (opts.hasOwnProperty('duration')) {
      this._duration = opts.duration;
    }

    this._tDelay = setTimeout(this._inject.bind(this), this._delay);
  }

  _inject() {
    this._build();
    this._bind();
    this._tDestroy = setTimeout(this._destroy.bind(this), this._duration);
    this._dom.notification.classList.add('show');
  }

  _build() {
    console.warn('method _build() must be implemented');
  }

  _bind() {
    this._dom.notification.addEventListener('click', this._destroy.bind(this));
  }

  _destroy() {
    this._dom.notification.classList.remove('show');
    clearTimeout(this._tDestroy);
    clearTimeout(this._tDelay);
    setTimeout(() => {
      this._dom.container.removeChild(this._dom.notification);
    }, this._cssDelay);
  }
}

class Flash extends Notification {
  constructor(container, opts) {
    super(container, opts);
    this._text = opts.text;
    this._type = opts.type || 'success';
  }

  _build() {
    const html = `
<div class="notification ${this._type}">
    ${this._text}
    <span class="close js-close"></span>
</div>
`;
    const element = document.createElement('div');
    element.innerHTML = html.trim();
    this._dom.notification = element.firstChild;
    this._dom.container.appendChild(this._dom.notification);
  }
}

class Toast extends Notification {
  constructor(container, opts) {
    super(container, opts);
    this._title = opts.title;
    this._content = opts.content;
    this._icon = opts.icon || 'selection-thumb-up';
    this._type = opts.type || 'success';
    this._actions = opts.actions || [];
  }

  _build() {
    const actionsHtml = this._buildActions();

    const html = `
<div class="toast ${this._type}">
    <div class="toast-header">
        <div class="toast-icon">${icon(this._icon)}</div>
        <button class="close js-close" title="Fermer">${icon('connexion_croix')}</button>
    </div>
    <div class="toast-body">
        <div class="toast-title">${this._title}</div>
        <div class="toast-content">${this._content}</div>
        ${actionsHtml}
    </div>
</div>
`;
    const element = document.createElement('div');
    element.innerHTML = html.trim();
    this._dom.notification = element.firstChild;
    this._dom.container.appendChild(this._dom.notification);
  }

  _buildActions() {
    const buttons = [];

    this._actions.forEach((action) => {
      const classAttribute = action.class || '';
      const typeAttribute = action.type || 'button';
      const label = action.label;
      const html = `
<div class="toast-button">
    <button class="ct--button cta ${classAttribute}" type="${typeAttribute}">${label}</button>
</div>
      `;

      buttons.push(html.trim());
    });

    return buttons.join('\n');
  }
}
