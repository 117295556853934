<script>
import Multiselect from 'vue-multiselect';

// noinspection JSUnusedGlobalSymbols
export default {
  extends: Multiselect,
  computed: {
    isPlaceholderVisible() {
      return true;
    },
  },
};
</script>
