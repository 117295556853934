/**
 * Return an element
 *
 * @param  {string}  selector
 * @param  {Element} container
 * @return {Element}
 */
export function findDOM(selector, container = document) {
  return container.querySelector(selector);
}

/**
 * Return an array of elements
 *
 * @param  {string}  selector
 * @param  {Element} container
 * @return {Array}
 */
export function findAllDOM(selector, container = document) {
  return Array.prototype.slice.call(container.querySelectorAll(selector), 0);
}
