<template>
  <div
    v-if="drink"
    class="selection-modal add-menu-modal"
  >
    <div class="selection-modal-content">
      <button
        class="close-button"
        title="Fermer"
        @click="close()"
      >
        <icon icon="panier_picto_suppr" />
      </button>
      <!-- IMAGE -->
      <div class="visual">
        <div class="img-wrapper">
          <img
            :src="drink.image.url"
            alt="Aperçu de la prestation"
          >
        </div>
      </div>
      <!-- TEXT -->
      <div class="functional">
        <div class="content">
          <h2 class="title-2">
            Ajouter "{{ drink.title }}" à votre sélection ?
          </h2>
          <form @submit.prevent="submit">
            <div class="menu-infos">
              <div class="quantity-info">
                <span class="title-2">x</span>
                <input
                  v-model="payload.quantity"
                  type="number"
                  class="quantity-input"
                  :min="drink.add_options.quantity.min"
                  :max="drink.add_options.quantity.max"
                  @change="inputUpdate"
                >
              </div>
              <div class="price-info">
                <span class="title-1">{{ price|format_price }} € HT</span>
              </div>
            </div>
            <!-- QUANTITY -->
            <div
              v-if="drink.add_options.quantity"
              class="slider-wrapper"
            >
              <p class="text-1">
                Sélectionnez la quantité désirée :
              </p>
              <slider
                ref="quantitySlider"
                :config="slider"
                :values="[payload.quantity]"
                @change="sliderUpdate"
              />
            </div>
            <div class="form-actions">
              <button
                class="ct ct--button cta cta-clear"
                @click="close()"
              >
                Annuler
              </button>
              <button
                type="submit"
                class="ct ct--button cta"
              >
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../../helpers/product-api';
import Icon from '../../../icon/Icon.vue';
import Slider from './Slider.vue';

export default {
  name: 'AddDrinkToSelectionModal',
  components: { Slider, Icon },
  filters: {
    format_price: function (value) {
      return Math.round(value / 100);
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    close: {
      type: Function,
      required: true,
    },
    selectionManager: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      drink: null,
      payload: null,
      slider: {
        step: 1,
        range: {
          min: 1,
          max: 10,
        },
      },
    };
  },
  computed: {
    price: function () {
      return this.payload.quantity * this.drink.unit_price;
    },
  },
  mounted: function () {
    api.getDrink(this.item.id).then((response) => {
      const options = response.data.data.add_options;
      const payload = {};
      if (options['quantity']) {
        payload.quantity = options['quantity']['min'];
      }
      this.payload = payload;
      this.slider = {
        step: options.quantity.step,
        range: {
          min: options.quantity.min,
          max: options.quantity.max,
        },
      };
      this.drink = response.data.data;
    });
  },
  methods: {
    submit: function () {
      const payload = { id: this.item.id, type: this.item.type, data: this.payload };
      this.selectionManager.addItemToSelection(payload).catch((error) => {
        if (error.response && error.response.status === 401) {
          return;
        }
        throw error; // if 400 => handle errors
      }).finally(() => {
        this.close();
      });
    },
    sliderUpdate: function (value) {
      this.payload.quantity = parseInt(value[0]);
    },
    inputUpdate: function (changeEvent) {
      this.$refs.quantitySlider.updateSlider(parseInt(changeEvent.target.value));
    },
  },
};
</script>
