<template>
  <form
    class="generic-form"
    @submit.prevent="submit"
  >
    <div
      v-if="loaded === false"
      class="form-body-placeholder"
    />
    <div
      v-if="loaded === true"
      class="form-body"
    >
      <h3 class="title-4">
        Nature de l'événement
      </h3>
      <event-brief-step-form-errors
        v-if="errors"
        :dismiss="dismissErrors"
        :errors="errors"
      />
      <div class="form-row">
        <!-- NOMBRE -->
        <div
          v-if="fields.guestsNumber === true"
          class="form-col"
        >
          <div
            class="form-group form-guests"
            :class="{ 'has-errors': isGuestsNumberFieldInvalid }"
          >
            <label
              class="required"
              for="edit-event-guests"
            >Combien de participants attendez vous ?</label>
            <input
              id="edit-event-guests"
              v-model="payload.guestsNumber"
              type="number"
              min="10"
              class="form-control form-control-inline"
              required
            >
            participants
          </div>
        </div>
        <!-- QUI -->
        <div
          v-if="fields.guestsCategories === true"
          class="form-col"
        >
          <div class="form-group form-attendees">
            <label for="edit-event-attendees">Qui seront les participants à votre événement ?</label>
            <event-brief-multi-select
              id="edit-event-attendees"
              v-model="payload.guestsCategories"
              :options="values.guestsCategories"
              :close-on-select="false"
              :searchable="false"
              :hide-selected="false"
              :multiple="true"
              label="label"
              track-by="value"
              placeholder=""
              selected-label="✓"
              select-label=""
              deselect-label=""
              class="no-placeholder"
            >
              <template
                slot="selection"
                slot-scope="props"
              >
                <span class="multiselect__resume">{{ joinValues(props.values) }}</span>
              </template>
            </event-brief-multi-select>
          </div>
        </div>
      </div>
      <div class="form-row">
        <!-- OBJECTIF -->
        <div
          v-if="fields.objectives === true"
          class="form-col"
        >
          <div class="form-group form-objectives">
            <label for="edit-event-objectives">Quel est l'objectif de votre événement ?</label>
            <event-brief-multi-select
              id="edit-event-objectives"
              v-model="payload.objectives"
              :options="values.objectives"
              :close-on-select="false"
              :searchable="false"
              :hide-selected="false"
              :multiple="true"
              label="label"
              track-by="value"
              placeholder=""
              selected-label="✓"
              select-label=""
              deselect-label=""
              class="no-placeholder"
            >
              <template
                slot="selection"
                slot-scope="props"
              >
                <span class="multiselect__resume">{{ joinValues(props.values) }}</span>
              </template>
            </event-brief-multi-select>
          </div>
        </div>
        <!-- PRESTATIONS -->
        <div
          v-if="fields.services === true"
          class="form-col"
        >
          <div
            class="form-group form-services"
            :class="{ 'has-errors': isServiceFieldInvalid }"
          >
            <label
              class="required"
              for="edit-event-services"
            >
              De quel type de prestations avez vous besoin ?
            </label>
            <event-brief-multi-select
              id="edit-event-services"
              v-model="payload.services"
              :options="values.services"
              :close-on-select="false"
              :searchable="false"
              :hide-selected="false"
              :multiple="true"
              label="label"
              track-by="value"
              placeholder=""
              selected-label="✓"
              select-label=""
              deselect-label=""
              class="no-placeholder"
              required
            >
              <template
                slot="selection"
                slot-scope="props"
              >
                <span class="multiselect__resume">{{ joinValues(props.values) }}</span>
              </template>
              <template
                slot="option"
                slot-scope="props"
              >
                <icon :icon="'nav_picto_' + props.option.reference" />
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.label }}
                  </span>
                </span>
              </template>
            </event-brief-multi-select>
            <div
              v-if="isServiceFieldInvalid"
              class="form-error"
            >
              {{ inlineerrors.services }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <!-- PROGRAMME -->
        <div
          v-if="fields.schedule === true"
          class="form-group form-program"
        >
          <label class="checkbox checkbox-custom">
            <input
              v-model="payload.withSchedule"
              type="checkbox"
            >
            <span>Je connais le programme de l'événement</span>
          </label>
          <textarea
            id="edit-event-program-desc"
            v-model="payload.schedule"
            class="form-control"
            placeholder="Par exemple : Buffet pour le déjeuner, puis projection en conférence dans l'après-midi suivie d'une collation en milieu d'après-midi ..."
            :disabled="!payload.withSchedule"
            required
          />
        </div>
      </div>
      <div
        v-if="fields.noiseLevel === true"
        class="form-row"
      >
        <label>Volume sonore prévu</label>
        <div class="form-group form-budget">
          <select
            v-model="payload.noiseLevel"
            class="form-control"
          >
            <option value="" />
            <option
              v-for="choice in values.noiseLevels"
              :key="choice.value"
              :value="choice.value"
            >
              {{ choice.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="form-actions">
      <button
        type="button"
        class="ct ct--button cta cta-clear"
        @click="cancel()"
      >
        <icon icon="arrow-left-solid" />
        Général (1/3)
      </button>
      <button
        type="submit"
        class="ct ct--button cta"
      >
        Besoins (3/3)
        <icon icon="arrow-right-solid" />
      </button>
    </div>
  </form>
</template>

<script>
import api from '../../../../../helpers/selection-api';
import Icon from '../../../../icon/Icon.vue';
import EventBriefStepFormErrors from './EventBriefFormErrors.vue';
import EventBriefMultiSelect from './EventBriefMultiSelect.vue';

export default {
  name: 'EventBriefStep1Form',
  components: {
    EventBriefMultiSelect,
    EventBriefStepFormErrors,
    Icon,
  },
  props: {
    selectionManager: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      errors: null,
      inlineerrors: {},
      values: {
        guestsCategories: [],
        objectives: [],
        services: [],
        noiseLevels: [],
      },
      fields: {
        guestsNumber: true,
        guestsCategories: false,
        objectives: false,
        services: false,
        withSchedule: false,
        schedule: false,
        noiseLevel: false,
      },
      payload: {
        guestsNumber: null,
        guestsCategories: [],
        objectives: [],
        services: [],
        schedule: null,
        withSchedule: null,
        noiseLevel: null,
      },
    };
  },
  computed: {
    isGuestsNumberFieldInvalid() {
      return !!this.inlineerrors.guestsNumber;
    },
    isServiceFieldInvalid() {
      return !!this.inlineerrors.services;
    },
  },
  mounted: function () {
    Promise.all([api.get(), api.getBriefStep2Values()]).then(([selection, values]) => {
      const event = selection.data.selection.event;
      // prepare fields choices
      this.values = { ...values.data.data };

      // if services array is empty, prefill it with values based on selection items
      if (this.values.services && event.services.length === 0 && selection.data.selection.items.length > 0) {
        selection.data.selection.items.forEach((item) => {
          const match = this.values.services.find((service) => service.sub_type === item.item_sub_type);
          if (!event.services.find((service) => match.value === service)) {
            event.services.push(match.value);
          }
        });
      }

      // prepare payload based on current event values
      this.payload = {
        guestsNumber: event.guestsNumber,
        guestsCategories: (event.guestsCategories || []).map((value) => {
          if (this.values.guestsCategories && this.values.guestsCategories.find((cat) => cat.value === value)) {
            return this.values.guestsCategories.find((cat) => cat.value === value);
          }
          return null;
        }).filter((item) => item !== null),
        objectives: (event.objectives || []).map((value) => {
          if (this.values.objectives && this.values.objectives.find((item) => item.value === value)) {
            return this.values.objectives.find((item) => item.value === value);
          }
          return null;
        }).filter((item) => item !== null),
        services: (event.services || []).map((value) => {
          if (this.values.services && this.values.services.find((cat) => cat.value === value)) {
            return this.values.services.find((cat) => cat.value === value);
          }
          return null;
        }).filter((item) => item !== null),
        withSchedule: event.withSchedule,
        schedule: event.schedule,
        noiseLevel: event.noiseLevel,
      };
      // prepare fields to be displayed
      Object.keys(this.fields).forEach((field) => {
        this.fields[field] = typeof values.data.data[field] !== 'undefined';
      });
      // hack...
      this.fields.noiseLevel = typeof values.data.data['noiseLevels'] !== 'undefined';
      this.loaded = true;
    });
  },
  methods: {
    cancel() {
      this.$emit('cancelled');
    },
    submit() {
      // validation inline
      if (this.fields.guestsNumber && (this.payload.guestsNumber === null || this.payload.guestsNumber < 1)) {
        this.inlineerrors = { ...this.inlineerrors, guestsNumber: 'Merci de renseigner le nombre de participants' };
        return;
      }
      if (this.fields.services && this.payload.services.length === 0) {
        this.inlineerrors = { ...this.inlineerrors, services: 'Merci de choisir un ou plusieurs services' };
        return;
      }
      // payload preparation
      const payload = { ...this.payload };
      payload.guestsCategories = payload.guestsCategories.map((option) => option.value);
      payload.objectives = payload.objectives.map((option) => option.value);
      payload.services = payload.services.map((option) => option.value);
      payload.schedule = payload.withSchedule ? payload.schedule : null;
      // call api
      this.selectionManager.updateBriefStep2(payload).then(() => {
        this.$emit('submitted');
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data.data;
        }
      });
    },
    dismissErrors() {
      this.errors = null;
    },
    joinValues(values) {
      if (!values) {
        return '';
      }
      return values.map((cat) => cat.label).join(', ');
    },
  },
};
</script>
