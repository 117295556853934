<template>
  <div class="form-errors">
    <ul>
      <li
        v-for="(item, index) in errors"
        :key="index"
      >
        {{ index }} : {{ item[0] }}
      </li>
    </ul>
    <button
      type="button"
      class="dismiss-button"
      @click="dismiss"
    >
      <icon icon="panier_picto_suppr" />
    </button>
  </div>
</template>

<script>
import Icon from '../../../../icon/Icon.vue';

export default {
  name: 'EventBriefStepFormErrors',
  components: {
    Icon,
  },
  props: {
    dismiss: {
      type: Function,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
};
</script>
