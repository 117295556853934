var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-errors" }, [
    _c(
      "ul",
      _vm._l(_vm.errors, function (item, index) {
        return _c("li", { key: index }, [
          _vm._v(
            "\n      " + _vm._s(index) + " : " + _vm._s(item[0]) + "\n    "
          ),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "dismiss-button",
        attrs: { type: "button" },
        on: { click: _vm.dismiss },
      },
      [_c("icon", { attrs: { icon: "panier_picto_suppr" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }