<template>
  <div class="selection-modal confirm-clear-selection-modal">
    <div class="selection-modal-content">
      <button
        class="close-button"
        title="Fermer"
        @click="close()"
      >
        <icon icon="panier_picto_suppr" />
      </button>
      <div class="full">
        <div class="content">
          <h2 class="title-2">
            Attention ! Vous êtes sur le point de vider votre sélection
          </h2>
          <p class="text-1">
            Votre sélection de lieux et de prestations sera totalement vide après cette action.
          </p>
          <button
            class="ct ct--button cta cta-clear"
            @click="close()"
          >
            Retourner à ma navigation
          </button>
          <button
            class="ct ct--button cta"
            @click="clear()"
          >
            <icon icon="panier_trash" />
            Vider ma sélection
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../../../icon/Icon.vue';

export default {
  name: 'ConfirmClearSelectionModal',
  components: {
    Icon,
  },
  props: {
    close: {
      type: Function,
      required: true,
    },
    selectionManager: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clear: function () {
      this.selectionManager.clearSelection().finally(() => this.close());
    },
  },
};
</script>
