<template>
  <div
    ref="element"
    class="vue-nouislider"
  />
</template>

<script>
import noUiSlider from 'nouislider';

export default {
  name: 'Slider',
  props: {
    config: {
      type: Object,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      slider: null,
    };
  },
  mounted: function () {
    this.slider = this.$refs.element;
    noUiSlider.create(this.slider, { ...this.config, start: this.values });
    this.slider.noUiSlider.on('update', this.updateValue);
  },
  methods: {
    updateValue: function (value, handle) {
      this.$set(this.values, handle, value[handle]);
      this.$emit('change', value);
    },
    updateSlider: function (value) {
      this.slider.noUiSlider.set(value);
    },
  },
};
</script>
