import { render, staticRenderFns } from "./SelectionBullet.vue?vue&type=template&id=08ad3710&"
import script from "./SelectionBullet.vue?vue&type=script&lang=js&"
export * from "./SelectionBullet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/project/assets/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08ad3710')) {
      api.createRecord('08ad3710', component.options)
    } else {
      api.reload('08ad3710', component.options)
    }
    module.hot.accept("./SelectionBullet.vue?vue&type=template&id=08ad3710&", function () {
      api.rerender('08ad3710', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/selection/bullet/SelectionBullet.vue"
export default component.exports