var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "generic-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _vm.loaded === false
        ? _c("div", { staticClass: "form-body-placeholder" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded === true
        ? _c(
            "div",
            { staticClass: "form-body" },
            [
              _c("h3", { staticClass: "title-4" }, [
                _vm._v("\n      Besoins optionnels\n    "),
              ]),
              _vm._v(" "),
              _vm.errors
                ? _c("event-brief-step-form-errors", {
                    attrs: { dismiss: _vm.dismissErrors, errors: _vm.errors },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.fields.venueSetup === true
                ? _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group form-layout" }, [
                      _c("label", { staticClass: "checkbox checkbox-custom" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.payload.withVenueSetup,
                              expression: "payload.withVenueSetup",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.payload.withVenueSetup)
                              ? _vm._i(_vm.payload.withVenueSetup, null) > -1
                              : _vm.payload.withVenueSetup,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.payload.withVenueSetup,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.payload,
                                      "withVenueSetup",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.payload,
                                      "withVenueSetup",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.payload, "withVenueSetup", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("J'ai besoin de plusieurs salles")]),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.payload.venueSetup,
                            expression: "payload.venueSetup",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "edit-event-layout-desc",
                          placeholder:
                            "Préciser le nombre de salles, leur type et leur capacité",
                          disabled: !_vm.payload.withVenueSetup,
                        },
                        domProps: { value: _vm.payload.venueSetup },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.payload,
                              "venueSetup",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              false
                ? _c("div", { staticClass: "form-row form-row-bedding" }, [
                    _c("div", { staticClass: "form-group form-bedding" }, [
                      _c("label", { staticClass: "checkbox checkbox-custom" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.payload.withBedding,
                              expression: "payload.withBedding",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.payload.withBedding)
                              ? _vm._i(_vm.payload.withBedding, null) > -1
                              : _vm.payload.withBedding,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.payload.withBedding,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.payload,
                                      "withBedding",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.payload,
                                      "withBedding",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.payload, "withBedding", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("J'ai besoin d'un hébergement")]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.payload.beddingNumber,
                              expression: "payload.beddingNumber",
                            },
                          ],
                          staticClass: "form-control form-control-inline",
                          attrs: {
                            id: "edit-event-accommodation",
                            type: "number",
                            min: "1",
                            disabled: !_vm.payload.withBedding,
                          },
                          domProps: { value: _vm.payload.beddingNumber },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.payload,
                                "beddingNumber",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("\n          couchages\n        "),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.fields.equipments === true
                ? _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-group form-equipments" },
                      [
                        _c(
                          "label",
                          { staticClass: "checkbox checkbox-custom" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.payload.withEquipment,
                                  expression: "payload.withEquipment",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.payload.withEquipment
                                )
                                  ? _vm._i(_vm.payload.withEquipment, null) > -1
                                  : _vm.payload.withEquipment,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.payload.withEquipment,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.payload,
                                          "withEquipment",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.payload,
                                          "withEquipment",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.payload, "withEquipment", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v("J'ai besoin d'un équipement particulier"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("event-brief-multi-select", {
                          staticClass: "no-placeholder",
                          attrs: {
                            id: "edit-event-category",
                            options: _vm.values.equipments,
                            "close-on-select": false,
                            searchable: false,
                            "hide-selected": false,
                            multiple: true,
                            label: "label",
                            "track-by": "value",
                            placeholder: "",
                            "selected-label": "✓",
                            "select-label": "",
                            "deselect-label": "",
                            disabled: !_vm.payload.withEquipment,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "multiselect__resume" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.joinValues(props.values))
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            751771988
                          ),
                          model: {
                            value: _vm.payload.equipments,
                            callback: function ($$v) {
                              _vm.$set(_vm.payload, "equipments", $$v)
                            },
                            expression: "payload.equipments",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.fields.remarks === true
                ? _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "form-group form-remarks" }, [
                      _c("label", { staticClass: "checkbox checkbox-custom" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.payload.withRemarks,
                              expression: "payload.withRemarks",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.payload.withRemarks)
                              ? _vm._i(_vm.payload.withRemarks, null) > -1
                              : _vm.payload.withRemarks,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.payload.withRemarks,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.payload,
                                      "withRemarks",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.payload,
                                      "withRemarks",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.payload, "withRemarks", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v("J'ai des remarques particulières"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.payload.remarks,
                            expression: "payload.remarks",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "edit-event-remarks",
                          placeholder:
                            "Accès à la lumière du jour, allergies particulières, contraintes spécifique, pas d'animaux, besoin d'un espace fumeur obligatoirement",
                          disabled: !_vm.payload.withRemarks,
                        },
                        domProps: { value: _vm.payload.remarks },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.payload,
                              "remarks",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-actions" }, [
        _c(
          "button",
          {
            staticClass: "ct ct--button cta cta-clear",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.cancel()
              },
            },
          },
          [
            _c("icon", { attrs: { icon: "arrow-left-solid" } }),
            _vm._v("\n      Prestation (2/3)\n    "),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "ct ct--button cta", attrs: { type: "submit" } },
          [_vm._v("\n      C'est parti !\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }